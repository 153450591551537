.UnlimitedNotification {
  background-color: $white;
  margin-bottom: 24px;
  padding: 12px;
  display: flex;
  gap: 12px;

  &-title {
    text-transform: none;
    font-size: 22px;
    font-weight: 500;
  }

  &-description {
    font-size: 16px;
  }
}

.PaymentSuccess {
  .UnlimitedNotification {
    background-color: $gray-1;
    margin-bottom: 0;
    width: 100%;
  }
}