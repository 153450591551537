.HeaderContainer {
  @include mobile {
    display: grid;
  }
}

.Header {
  background-color: $primary;
  display: flex;
  height: $header-height;

  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;

  &Container {
    @extend .PageContent;
    margin: auto;

    @include tablet-down {
      padding-bottom: 0;
      padding-top: 0;
    }
  }

  .CvoHeader {
    align-items: center;

    &-logoContainer {
      @include mobile {
        display: flex;
        margin: auto;
      }
    }

    &-actions {
      align-items: center;
      display: flex;
      flex-direction: row-reverse;
      margin-#{$end}: 0;

      &:after {
        content: '';
        display: inline-block;
        height: 20px;
        border: 1px solid $primary-light;
      }

      @include mobile {
        display: none;
      }
    }

    &-languageContainer {
      align-items: center;
      display: flex;
      margin-inline: 0;

      &:before {
        content: '';
        display: inline-block;
        height: 20px;
        border: 1px solid $primary-light;
      }
    }

    &-loginAccountWrapper {
      position: relative;
    }

    &-loginButton {
      background: none;
      border: none;
      padding-bottom: 2px;
      padding-top: 6px;
    }

    &-userButton {
      background: none;
      border: none;
      display: flex;
      align-items: end;
      padding: 18px;

      &:after {
        background-color: $white;
        content: '';
        display: inline-block;
        height: 24px;
        mask-image: url($icons-sprite + '#chevron-down');
        width: 24px;
      }

      // Using this class as "is-open" state, as the component does not provide it
      &.CvoOverlaySiblingIndex {
        background: $white;
        border-radius: 0;
        color: $primary;

        &:after {
          background-color: $primary;
        }
      }
    }

    &-userPanel {
      background: $white;
      padding: 18px;

      > hr {
        display: none;
      }
    }

    &-fullName {
      display: none;
    }

    &-userAreaButton,
    &-logoutButton {
      @include text-m-figma;

      align-items: center;
      background: none;
      border: none;
      border-radius: 0;
      color: $gray-5;
      display: flex;
      text-transform: none;

      &:before {
        content: '';
        display: inline-block;
        height: 24px;
        margin-#{$end}: 8px;
        width: 24px;
      }
    }

    &-userAreaButton:before {
      background-image: url($icons-sprite + '#account-icon');
    }

    &-logoutButton:before {
      background-image: url($icons-sprite + '#logout');
    }

    &-userLinks {
      display: none;
    }

    .CvoSelectableList {
      &-wrapper {
        position: relative;
      }

      &-button {
        display: flex;
        align-items: center;
        padding: 18px;

        &:before {
          content: '';
          display: inline-block;
          height: 24px;
          width: 24px;
        }

        &.en:before {
          background: url($icons-sprite + '#united-kingdom');
        }

        &.it:before {
          background: url($icons-sprite + '#italy');
        }

        &.pl:before {
          background: url($icons-sprite + '#poland');
        }

        &.is-open {
          background: $white;
        }
      }

      &-buttonText {
        color: $white;
        display: none;
      }

      &-arrow {
        background-color: $white;
        border: none;
        display: inline-block;
        height: 24px;
        mask-image: url($icons-sprite + '#chevron-down');
        width: 24px;

        &--open {
          background-color: $primary;
          mask-image: url($icons-sprite + '#chevron-up');
        }
      }

      &-listWrapper {
        background: $white;
        padding: 16px 0;
        position: absolute;
        #{$end}: 0;
        border-radius: $border-radius;
        border-top-#{$end}-radius: 0;
      }

      &-item {
        display: flex;
        padding: 12px 24px;
        user-select: none;

        &:hover {
          background: $secondary-lighter;
        }

        &:before {
          content: '';
          display: inline-block;
          height: 24px;
          margin-#{$end}: 12px;
          width: 24px;
        }

        &.en:before {
          background: url($icons-sprite + '#united-kingdom');
        }

        &.it:before {
          background: url($icons-sprite + '#italy');
        }

        &.pl:before {
          background: url($icons-sprite + '#poland');
        }
      }
    }

    .CvoSlideMenu {
      display: none;

      @include mobile {
        display: block;
      }

      &-menuButton {
        background-color: $white;
        height: 32px;
        mask-image: url($icons-sprite + '#hamburger-menu');
        mask-position: center;
        margin-#{$end}: -32px;
        width: 32px;
      }

      &-panelWrapper {
        max-width: 0;
        min-width: 0;
        overflow: hidden;
        transition: all ease-in-out .4s;
        display: block;

        &.is-open {
          max-width: 100vw;
          min-width: 50vw;
        }
      }

      &-panel {
        min-width: 50vw;
        padding: 20px;
        height: 100%;
      }

      &-panelSection {
        display: flex;
        flex-direction: column;

        &:last-child {
          align-items: normal;
          flex-direction: row;
          margin-top: auto;

          span {
            display: inline-block;
            font-weight: bold;
            margin-#{$end}: 4px;
          }

          img {
            width: 70px;
          }

          a {
            display: flex;
            padding: 0;
            margin-top: 2px;
          }
        }

        > h2 {
          @include h6-figma;
          color: $gray-5;
        }

        > a {
          color: $secondary;
          margin-#{$end}: auto;
          padding: 8px 0;
          text-decoration: none;
        }
      }

      &-closeButton {
        background-color: $secondary;
        height: 32px;
        mask-image: url($icons-sprite + '#close');
        mask-position: center;
        width: 32px;
      }

      .CvoSelectableList {
        &-button {
          padding: 8px 0;
        }

        &-buttonText {
          color: $secondary;
          display: inline-block;
          margin-#{$start}: 8px;
        }

        &-arrow {
          background-color: $secondary;
        }

        &-listWrapper {
          display: block;
          max-height: 0;
          overflow: hidden;
          padding: 0;
          position: static;
          transition: max-height ease-in-out .4s;

          &.is-open {
            max-height: 900px;
          }
        }

        &-item {
          padding: 8px 0;
        }

        .CvoOverlay {
          display: none;
        }
      }
    }

    .SubscribeHeader {
      order: 1;
      margin-#{$end}: 20px;
    }
  }
}
