.CvoAutocomplete,
.Autocomplete {
  &:hover {
    .CvoClose {
      display: block;
    }
  }

  .autocomplete-result-list {
    border-color: $gray-4;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    z-index: 4 !important // overide inline;
  }

  .autocomplete-result {
    background: none;
    padding: 10px;
  }

  .autocomplete-input {
    background-image: none;
  }

  .CvoClose {
    background-color: $gray-3;
    display: none;
    padding: 0;
    top: 20px;
    z-index: 3;
  }

  &-clearButton {
    top: 29px;
  }
}
