.AirportOfPreference {
  article.FormBuilder form {
    padding-bottom: 16px;
  }

  &-description {
    @include text-xs-figma;
    color: $gray-4;
    background-color: $white;
    padding: 0 16px;
  }
}
