@import './mixins/carousel-viewport';

@mixin multi-option-colors($background-color, $text-color, $button-background, $button-text-color) {
  &::before {
    content: '';
    inset: -2px; // Avoid bug showing a thin line with the inside contents when the card is being dragged
    display: block;
    position: absolute;
    background: $background-color;

    clip-path: polygon(0 25%, 100% 11%, 100% 100%, 0 100%);

    @include carousel-viewport-xs {
      clip-path: polygon(0 23%, 100% 12%, 100% 100%, 0 100%);
    }
  }

  .MultiOptionPlan {
    &-submitButton {
      width: 100%;
      background: $button-background;
      color: $button-text-color;
    }

    &-benefits {
      li {
        p {
          @include text-m-figma;
          color: $text-color;
        }

        &::before {
          width: 20px;
          height: 20px;
          background-color: $text-color;
        }
      }
    }

    &-price, &-currency {
      @include h1-figma;
      text-transform: unset;

      @include carousel-viewport-xs {
        font-size: 36px;
      }
      line-height: 1;
      color: $text-color;
    }

    &-recurrency {
      @include text-s-figma;
      color: $text-color;
      white-space: nowrap;
      margin-#{$start}: 12px;
    }
  }

  p.MultiOptionPlan {
    &-badgeText {
      @include text-l-figma;
      line-height: 1;
      color: $white;
      font-weight: 500;
    }

    &-title {
      @include h5-figma(none);
      color: $primary;
      text-align: center;
      font-weight: 400;
    }

    &-subtitle {
      @include h2-figma(uppercase);
      color: $primary;
      text-align: center;
      font-weight: 500;
    }

    &-description {
      @include text-s-figma;
      color: $text-color;
      font-weight: 700;
      margin-top: 12px;
      text-transform: uppercase;
      text-align: center;
    }

    &-priceLabel {
      @include text-s-figma;
      color: $text-color;
      margin-top: 12px;
    }
  }
}

@mixin card-background($img) {
  background: url($s3-base-url + '/images/' + $img) no-repeat;
  background-size: contain;
}

// Loop card themes
.SlideWrapper {
  &:nth-child(3n-2) {
    .MultiOptionPlan {
      &:not(&--unlimited) {
        @include multi-option-colors($secondary, $white, $white, $secondary);
      }
    }
  }

  &:nth-child(3n-1) {
    .MultiOptionPlan {
      &:not(&--unlimited) {
        @include multi-option-colors($primary, $white, $white, $primary);
      }
    }
  }

  &:nth-child(3n) {
    .MultiOptionPlan {
      &:not(&--unlimited) {
        @include multi-option-colors($white, $primary, $secondary, $white);
      }
    }
  }

  .MultiOptionPlan--unlimited {
    @include multi-option-colors($gradient, $white, $secondary, $white);
  }
}


$el: '.MultiOptionPlan';

.MultiOptionPlan {
  position: relative;
  border-top: 4px solid $highlight-blue;
  border-radius: $border-radius;
  overflow: hidden;
  box-shadow: $box-shadow;

  &-badge {
    &::before {
      content: '';
      width: 24px;
      height: 24px;
    }
    position: absolute;
    #{$end}: 0;
    top: -4px;
    border-bottom-#{$start}-radius: $border-radius;
    border-bottom-#{$end}-radius: $border-radius;
    background-color: $highlight-blue;
    padding: 4px 16px;
    display: flex;
    align-items: end;
    gap: 4px;
    text-transform: uppercase;
  }

  &-container {
    position: relative;
    display: flex;
    flex-flow: column;
    height: 100%;
    padding: 20px;
  }

  &-titleContainer {
    border-radius: $border-radius;
    background-color: $white;
    padding: 8px 32px;
    margin-top: 45px;
    margin-#{$start}: auto;
    margin-#{$end}: auto;
    box-shadow: $box-shadow-sm;
  }

  &-billingPeriod {
    @include text-xs-figma;

    display: block;
    max-height: 20px;
    min-height: 20px;
  }

  &-benefits {
    display: flex;
    flex-direction: column;
    gap: 4px;

    li {
      display: flex;
      align-items: start;
      gap: 6px;

      a {
        color: $white;
        font-weight: 700;
      }
    }
  }

  &-tagContainer {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 8px;
  }

  &-tag {
    @include text-s-figma;
    font-weight: 700;
    padding: 2px 12px;
    text-transform: uppercase;
    border-radius: $border-radius;
    height: min-content;

    sup {
      line-height: 0;
    }
    &--highlight {
      background-color: $highlight-blue;
      color: $white;
    }
    &--warning {
      background-color: $warning;
    }
  }

  .Form {
    height: 100%;
    order: 3;
    flex: 1;

    > div {
      height: 100%;

      > form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }
    }

    .Radio {
      &-controlsContainer {
        display: flex;
        margin-top: 24px;

        .control {
          flex-grow: 1;
          flex-basis: 100%;
          transition: border-color 200ms;
          border: 1px solid $primary;
          background-color: $white;

          &:first-child {
            border-top-#{$start}-radius: $border-radius;
            border-bottom-#{$start}-radius: $border-radius;
            border-#{$end}: none;
          }

          &:last-child {
            border-top-#{$end}-radius: $border-radius;
            border-bottom-#{$end}-radius: $border-radius;
            border-#{$start}: none;
          }

          label {
            @include text-m-figma;
            transition: color 200ms ease-in-out;
            color: $primary;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
            padding: 12px 0;
            text-wrap: nowrap;

            @include mobile {
              padding: 12px;
              text-wrap: wrap;
            }
          }

          input {
            display: none;
          }
          &.is-checked {
            border: 1px solid $secondary;
            box-shadow: $box-shadow-sm;

            label {
              display: flex;
              align-items: center;
              gap: 4px;
              color: $secondary;

              &::before {
                content: '';
                filter: unset;
                mask-image: url($icons-sprite + '#check');
                mask-repeat: no-repeat;
                mask-position: center;
                background-color: $success;
                width: 20px;
                height: 20px;
              }
            }
          }
        }
      }
    }
  }

  &-link {
    display: none;
  }

  &-separator {
    margin-top: 12px;
    opacity: .4;
  }

  &-submitButton {
    margin-top: 24px;
    border-width: 0;
  }

  &-priceContainer {
    text-align: $start;
  }

  // Plan specific styles
  &--italy {
    @include card-background('it-ow.png');

    #{$el}-badge::before {
      background: url($icons-sprite + '#italy');
    }
  }

  &--poland {
    @include card-background('pl-ow.png');

    #{$el}-badge::before {
      background: url($icons-sprite + '#poland');
    }
  }

  &--albania {
    @include card-background('alb-ow.jpeg');
    background-position-y: -40px;

    #{$el}-badge::before {
      background: url($icons-sprite + '#albania');
    }
  }

  &--uk {
    @include card-background('uk-ow.jpg');

    #{$el}-badge::before {
      background: url($icons-sprite + '#united-kingdom');
    }
  }

  &--emirates {
    @include card-background('uae-ow.jpg');

    #{$el}-badge::before {
      background: url($icons-sprite + '#united-arab-emirates');
    }
  }

  &--hungary {
    @include card-background('hu-ow.png');

    #{$el}-badge::before {
      background: url($icons-sprite + '#hungary');
    }
  }

  &--romania {
    @include card-background('ro-ow.png');

    #{$el}-badge::before {
      background: url($icons-sprite + '#romania');
    }
  }

  &--austria {
    @include card-background('at-ow.png');

    background-position-y: -95px;

    @include carousel-viewport-xs {
      background-position-y: -50px;
    }

    #{$el}-badge::before {
      background: url($icons-sprite + '#austria');
    }
  }

  &--bulgaria {
    background-position-y: -60px;

    @include card-background('bg-ow.png');

    @include carousel-viewport-xs {
      background-position-y: -35px;
    }

    #{$el}-badge::before {
      background: url($icons-sprite + '#bulgaria');
    }
  }

  &--cyprus {
    background-position-y: -35px;

    @include card-background('cy-ow.png');

    @include carousel-viewport-xs {
      background-position-y: -20px;
    }

    #{$el}-badge::before {
      background: url($icons-sprite + '#cyprus');
    }
  }

  &--unlimited {
    @include card-background('unlimited.png');
    background-position-y: -85px;

    @include carousel-viewport-xs {
      background-position-y: -50px;
    }

    #{$el} {
      &-badge::before {
        background: url($icons-sprite + '#full-network');
      }
    }
  }
}
