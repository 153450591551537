$el: '.CvoSearchFlight';

.CvoSearchFlight {
  @extend .CvoFormBuilder;

  &--RT,
  &--RT.CvoSearchFlight--wallet,
  &--RT.CvoSearchFlight--availabilityCombo {
    .CvoCheckbox {
      display: flex;
      margin-#{$start}: 20px;
      &-wrapper {
        align-self: center;
      }

      @include tablet-down {
        margin: 20px 0;
      }
    }

    @include tablet {
      .CvoDatepicker {
        min-width: unset;
      }
    }
  }

  &--RT.CvoSearchFlight--availabilityCombo {
    .CvoCheckbox {
      padding: 0 20px;
      &-wrapper {
        align-self: center;
      }
    }
  }

  &--OW {
    .CvoCheckbox {
      display: none;
    }
  }

  &-row:first-child {
    display: none;
  }

  &-row:last-child {
    position: relative;

    label {
      line-height: 24px;
    }
  }

  &-fieldsContainer {
    @include mobile {
      margin-bottom: 0;
    }
  }

  .CvoForm {
    position: relative;
    z-index: 1;

    input {
      background-image: none;
    }

    .CvoDatepicker {
      &-icon-calendar {
        display: none;
      }
      &-datepicker.disabled {
        input {
          background-color: $white;
          &::placeholder {
            color: $input-color;
            opacity: 1;
          }
        }
      }
    }

    form {
      background: $gray-1;
      border: 1px solid $gray-2;
      border-radius: $border-radius;
      box-shadow: $box-shadow-sm;
    }

    .CvoAutocomplete:nth-of-type(1),
    .CvoDatepicker:nth-of-type(1) {
      margin-#{$end}: 0;
      position:relative;

      &:after {
        background: url($icons-sprite + '#chevron-#{$end}') no-repeat center $white;
        content: '';
        filter: grayscale(100%);
        height: 20px;
        box-shadow: inset 0 0 0px 18px rgba(255, 255, 255, 0.5);
        position: absolute;
        #{$end}: -10px;
        top: 20px;
        width: 20px;
        z-index: 1;

        @include mobile {
          background-image: url($icons-sprite + '#chevron-down');
          bottom: -10px;
          #{$start}: 50%;
          margin-#{$start}: -10px;
          top: unset;
        }
      }

      input {
        border-#{$end}-style: dotted;
        border-top-#{$end}-radius: 0;
        border-bottom-#{$end}-radius: 0;
        @include mobile {
          border-#{$end}-style: solid;
          border-bottom-style: dotted;
          border-top-#{$end}-radius: 3px;
          border-bottom-#{$start}-radius: 0;
        }
      }
    }

    .CvoAutocomplete:nth-of-type(2),
    .CvoDatepicker:nth-of-type(2) {
      input {
        padding: 20px 0 0 10px;
        border-bottom-#{$start}-radius: 0;
        border-#{$start}: none;
        border-top-#{$start}-radius: 0;
        @include mobile {
          border-bottom-#{$start}-radius: 3px;
          border-#{$start}: 1px solid $gray-4;
          border-top-#{$end}-radius: 0;
          border-top: none;
        }
      }
    }
  }

  &--aycf:before {
    @include hero-private('aycf');
  }

  &--mp:before {
    @include hero-private('aycf');
  }

  // wallet combo
  &--wallet {
    padding: 128px 48px 0;
    position: relative;

    @include mobile {
      padding: 100px 48px 48px;
    }
    #{$el} {
      &-row:last-child {
        flex-wrap: wrap;

        .CvoSearchFlight-fieldsContainer {
          flex-wrap: wrap;
          min-width: 100%;

          @include mobile {
            flex-direction: row;
          }
        }
      }

      &-submit {
        bottom: 0;
        padding: 18px;
        position: absolute;
        #{$end}: 0;

        @include tablet-down {
          position: static;
          width: 100%;
        }
      }
    }

    .CvoAutocomplete {
      margin: 0 0 24px 0;
      min-width: 50%;

      @include mobile {
        min-width: 100%;

        &:first-child {
          margin-bottom: 0;
        }
      }

      input:focus {
        border-color: $gray-4;
      }
    }

    .CvoDatepicker {
      flex: none;
      margin: 0;
      min-width: 40%;

      @include tablet-down {
        margin: 0 0 24px 0;
        min-width: none;
        width: 50%;
      }

      @include mobile {
        min-width: 100%;

        &:first-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  &--availabilityCombo{
    .CvoAutocomplete {
      min-width: 25%;
    }
    #{$el} {
      &-submit {
        padding: 18px 24px;
      }
    }
  }
}

