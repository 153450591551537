.CustomizePlan {
  display: flex;
  flex-direction: column;

  @include mobile {
    padding-bottom: 40px;
  }
  
  &-cartContainer {
    background: $primary;
    margin-top: auto;
    
    @include mobile {
      bottom: 0;
      #{$start}: 0;
      position: fixed;
      width: 100%;
      z-index: 3;

      .PageContent {
        padding-bottom: 0;
        padding-top: 0;
      }
    }
  }
}