.CvoInputField,
.InputField,
.CvoPasswordField,
.CvoSelectField,
.SelectField,
.CvoDateField,
.CvoAutocomplete,
.Autocomplete {

  label,
  .label {
    @include text-s-figma;
    
    margin-bottom: -24px;
    padding: 0 8px;
    position: relative;
    z-index: 2;
    color: $gray-4;
    text-transform: uppercase;
    font-weight: 700;
  }

  input,
  .autocomplete-input,
  .select {
    border: 1px solid $gray-4;
    border-radius: 3px;
    padding: 20px 8px 0px;
    height: 59px;

    &.is-focus {
      border: 2px solid $primary;
    }

    &.is-danger {
      border: 2px solid $secondary;
      background-color: $secondary-lighter;
      input {
        background-color: $secondary-lighter;
      }
    }

    &.is-success {
      border-color: $gray-4;
    }

    &.is-success:active,
    &.is-success:focus {
      box-shadow: none;
      border-color: $gray-4;
    }
  }

  .select {
    select {
      background: none;
      border: none;
      box-shadow: none;
      margin-#{$start}: -8px;
      margin-#{$end}: -8px;
      padding: 8px;
    }
  }

  p.help {
    margin-top: 0.5rem;
  }
}

.CvoDateField {
  @include mobile {
    &-selectContainer {
      flex-direction: column;
    }

    .CvoSelectField:nth-child(1) .CvoForm-field,
    .SelectField:nth-child(1) .Form-field {
      border-bottom-#{$start}-radius: 0;
      border-bottom-#{$end}-radius: 0;
      border-bottom: none;
    }

    .CvoSelectField:nth-child(2) .CvoForm-field,
    .SelectField:nth-child(2) .Form-field {
      border-radius: 0;
    }

    .CvoSelectField:nth-child(3) .CvoForm-field,
    .SelectField:nth-child(3) .Form-field {
      border-top-#{$start}-radius: 0;
      border-top-#{$end}-radius: 0;
      border-top: none;
    }
  }


  .CvoSelectField,
  .SelectField {
    height: unset;
    border: unset;
    padding: unset;
    margin: unset;
  }

  .CvoSelectField:not(:last-child),
  .SelectField:not(:last-child) {
    margin-#{$end}: 20px;

    @include mobile {
      margin-#{$end}: 0;
    }
  }
}

.CvoRadio {
  margin-top: -6px;

  label {
    display: none;
  }

  &-controlsContainer {
    display: flex;
    height: 59px;
    align-items: stretch;

    .control {
      width: 50%;
      border: 1px solid $gray-4;

      label {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        @include text-m-figma;
        text-transform: unset;
        color: $primary;
      }

      input {
        display: none;
      }
    }

    .control:first-child {
      border-radius: 4px 0px 0px 4px;
    }
    .control:last-child {
      border-radius: 0px 4px 4px 0px;
    }
    .control.is-checked {
      border: 1px solid $secondary;
      box-shadow: $box-shadow;
      label {
        color: $secondary;
        &::before {
          filter: hue-rotate(260deg) brightness(3.75);
        }
      }
    }
  }
}

input[type="checkbox"] {
  width: 22px;
  min-width: 22px;
  height: 22px;
  min-height: 22px;
  line-height: 22px;
  appearance: none;
  outline: 2px solid $gray-4;
  box-shadow: none;
  text-align: center;
  background: $gray-1;
  border-radius: 3px;
  &:checked {
    background: $secondary;
    outline: 1px solid $secondary;
  }
  &:checked::after {
    content: '✔';
    color: white;
  }
  +label {
    text-transform: unset;
    a {
      text-transform: uppercase;
      color: $secondary;
      font-weight: 700;
    }
  }
}

.CvoDatepicker:hover {
  .CvoDatepicker-icon-clear {
    display: block;
  }
}

.CvoDatepicker-icon-clear {
  background-color: $gray-3;
  border-radius: 50%;
  height: 20px;
  max-height: 20px;
  max-width: 20px;
  min-height: 20px;
  min-width: 20px;
  width: 20px;
  z-index: 3;

  &:before {
    height: 2px;
    width: 50%;
  }

  &:after {
    height: 50%;
    width: 2px;
  }

  &:after,
  &:before {
    background-color: #fff;
    content: "";
    display: block;
    #{$start}: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transform-origin: center center;
  }

  svg {
    display: none;
  }
}

.CvoSelectField,
.SelectField {
  select,
  .select:not(.is-multiple):not(.is-loading) {
    &::after {
      right: unset; // reset default
      #{$end}: 1.125em;
    }

    select {
      padding-right: 12px; // reset default;
      padding-#{$end}: 2.5em;
    }
  }
}