.CvoInformationCard, .Card {
  @include card;

  gap: 16px;
  margin-bottom: 64px;

  h1 {
    @include h3-figma;
    text-transform: unset;
    margin-bottom: 16px;
  }

  h2 {
    @include text-m-figma;
    text-transform: unset;
    font-weight: 700;
  }

  ul {
    list-style: unset;
    list-style-position: inside;
  }

  button:not(.SecondaryButton) {
    @include --button-secondary-inverted;
    border: unset;
    font-size: 14px;

    @include mobile {
      padding: 10px 0;
      #{$end}: 24px;
      top: 12px;
    }
  }

  button:not(.SecondaryButton).InformationCard.unpaidAlertButton {
    color: $white;
    background-color: $secondary;
    margin-top: 24px;

    @include mobile {
      width: 100%;
    }
  }

  &-informationWrapper {
    padding-top: 14px;
  }

  .CvoDefinitionListWrapper, .Card-details {
    padding: 16px 24px 32px 16px;
    background-color: $gray-1;

    ul {
      display: flex;
      justify-content: space-between;
    }

    h2 {
      @include text-l-figma;
      text-transform: uppercase;
      font-weight: 600;
      color: $primary;
    }

    .CvoDefinitionList {
      &-item {
        flex-flow: column;
      }

      &-term {
        @include h6-figma;
        color: $gray-5;
        margin-bottom: 8px;
      }

      &-definitionGroup {
        text-align: unset;
      }
    }
  }

  &-content {
    margin-top: 8px;
  }
  .promotionalTag {
    background-color: $highlight-blue;
    color: $white;
    font-size: 14px;
    font-weight: 700;
    padding: 1.25px 10.02px;
    border-radius: 2px;
    margin-#{$start}: 7px;
    text-transform: uppercase;
  }

  &-extrasWrapper>div:first-child {
    text-align: $end;
  }
}

$el: '.CvoInformationCard';

// PaymentInformationCard
.PaymentInformationCard#{$el} {
  .CvoDefinitionListWrapper h2 {
    display: flex;

    &::before {
      content: '';
      display: inline-block;
      background: url($icons-sprite + '#credit-card') no-repeat;
      background-size: cover;
      height: 24px;
      margin-#{$end}: 10px;
      width: 24px;
    }
  }

  #{$el}-secondaryAction {
    display: flex;
    align-items: center;
    margin-#{$start}: auto;

    &::after {
      background-color: $secondary;
      content: '';
      display: inline-block;
      height: 16px;
      margin-#{$start}: 8px;
      mask-image: url($icons-sprite + '#pencil');
      mask-repeat: no-repeat;
      mask-size: cover;
      width: 16px;
    }

    &:hover::after {
      background-color: $secondary-light;
    }

    &:active::after,
    &:focus::after {
      background-color: $secondary-dark;
    }
  }
}

// PassengerInformationCard
.PassengerInformationCard#{$el} {
  .CvoDefinitionListWrapper h2 {
    display: flex;

    &::before {
      content: '';
      display: inline-block;
      background: url($icons-sprite + '#personal-info') no-repeat;
      background-size: cover;
      height: 24px;
      margin-#{$end}: 10px;
      width: 24px;
    }
  }
}

// Redeem payment success
.PassPaymentSuccess#{$el} {
  #{$el}-title {
    align-items: center;
    display: flex;
    color: $success;
    font-weight: 300;
    position: relative;

    &:before {
      border-radius: 24px;
      box-shadow: $box-shadow-sm;
      content: '';
      display: inline-block;
      height: 48px;
      margin-#{$end}: 12px;
      width: 48px;
    }

    &:after {
      background-color: $success;
      content: '';
      display: inline-block;
      height: 32px;
      #{$start}: 9px;
      mask-image: url($icons-sprite + '#check');
      mask-repeat: no-repeat;
      mask-size: cover;
      position: absolute;
      width: 32px;
    }
  }

  #{$el}-extrasWrapper {
    display: flex;
    flex-direction: column;

    > div {
      position: relative;
    }

    .SecondaryButton {
      margin-#{$start}: auto;
      margin-top: auto;

      @include mobile {
        width: 100%;
      }
    }
  }

  #{$el}-secondaryAction {
    position: absolute;
    #{$end}: -24px;
    top: -12px;
    text-indent: -9999px;
    overflow: hidden;

    &:after {
      background-color: $secondary;
      content: '';
      display: inline-block;
      height: 20px;
      mask-image: url($icons-sprite + '#close');
      mask-repeat: no-repeat;
      mask-size: cover;
      position: absolute;
      #{$end}: 5px;
      top: 5px;
      width: 20px;
    }
  }
}

// Flight redeem summary
.FlightSummary#{$el} {
  $fs: '.FlightSummary';

  #{$el}-extrasWrapper {
    display: none;
  }

  #{$fs}-subtitle {
    color: $secondary;
    margin-bottom: 38px;
  }

  #{$fs}-addons {
    padding-top: 22px;
  }

  #{$fs}-addonsTitle {
    @include h2-figma;
    font-weight: 600;
    margin-bottom: 12px;
  }

  #{$fs}-addonsList {
    list-style-type: none;
    padding: 0;

    li {
      @include h3-figma;
      color: $gray-5;
      display: flex;
      font-weight: 600;
      margin-#{$end}: 12px;
      text-transform: none;

      @include mobile {
        font-size: 16px;
      }

      &::before {
        content: '';
        background-color: $success;
        display: inline-block;
        height: 24px;
        margin-#{$end}: 12px;
        mask-image: url($icons-sprite + '#check');
        mask-repeat: no-repeat;
        mask-size: cover;
        width: 24px;
      }

    }
  }
}

.YourPlanCard#{$el}, .YourPlanCard.Card {

  .HighlightText {
    position: absolute;
    top: 0;
    #{$end}: 0;
    padding: 3px 15px;
    border-radius: 0 0 0 10px;
  }
  #{$el}-content:first-of-type {
    margin-bottom: 12px;
  }

  .CvoPlanDetails, .Card-details {
    position: relative;

    &--highlighted {
      border: none;
    }
  }

  .CvoPlanDetails-button {
    display: none;
  }

  .is {
    &-outstanding, &-void {
      border-top: 4px solid $danger;

      .HighlightText {
        background-color: $danger;
        color: $white;
      }
    }
    &-processing, &-suspended {
      border-top: 4px solid $gray-2;

      .HighlightText {
        background-color: $gray-2;
      }
    }
    &-paid {
      border-top: none;

      .HighlightText {
        display: none;
      }
    }
    &-pending {
      border-top: 4px solid $highlight-blue;

      .HighlightText {
        background-color: $highlight-blue;
        color: $white;
      }
    }
  }
}

.Card {
  display: flex;

  @include mobile {
    flex-direction: column;
  }

  &-informationWrapper, &-details {
    flex-basis: 50%;
    flex-grow: 1;
  }

  &.YourPlanCard .Card-details {
    .Card-title {
      display: flex;
      gap: 4px;
      .subs-icons {
        display: inline-block;
      }
    }

    .Card-title, .Card-text {
      text-transform: uppercase;
    }

    .YourPlanCard-counter {
      @include h3-figma;
      display: block;
    }
  }
}
