.Countdown {
  @include h1-figma;
  color: $white;
  display: flex;
  gap: 10px;

  @include tablet-down {
    justify-content: center;
  }

  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-number {
    line-height: 67px;
  }

  &-separator {
    margin-top: -10px;
  }

  &-unit {
    font-size: 16px;
    text-transform: none;
    letter-spacing: normal;
  }
}
