article.CvoNotification {
  border-radius: $border-radius;
  #{$start}: 50%;
  max-width: $desktop;
  position: fixed;
  top: $header-height;
  transform: translate(-50%, 0);
  width: 100%;
  z-index: 999;

  @include mobile {
    width: calc(100% - 40px);
  }

  .notification {
    padding: 18px 16px;
    position: relative;
    font-family: Arial, sans-serif;

    &.is-danger {
      background-color: $danger-light;
    }

    &.is-warning {
      background-color: $white;

      button.CvoClose::before {
        filter: $secondary-icon;
      }
    }

    .CvoClose {
      #{$end}: 16px;
      top: 18px;

      @include mobile {
        #{$end}: 6px;
        top: 6px;
      }
    }
  }

  // Register custom notification
  &--register {
    position: static;
    transform: none;
    width: 100%;

    .message-wrapper {
      display: flex;
      gap: 24px;
    }

    div.content {
      width: 100%;

      h2 {
        @include h3-figma;
        color: $secondary;
        text-transform: unset;
      }
    }
  }
}