$el: '.SearchCombo';

.SearchCombo {
  &-formWrapper {
    @extend .CvoFormBuilder;
  }

  &-row:first-child {
    display: none;
  }

  &-row:last-child {
    position: relative;

    label {
      line-height: 24px;
    }
  }

  &-fieldsContainer {
    @include mobile {
      margin-bottom: 0;
    }
  }

  &-currentSearch {
    border-#{$end}: 1px solid $gray-lighter;
    margin-#{$end}: 12px;
    padding-#{$end}: 12px;
  }

  &-currentRoute {
    margin-bottom: 12px;
  }

  &-route {
    font-weight: 600;
    font-size: 20px;
  }

  &-divider {
    border-top: 2px solid $gray-lighter;
  }

  &-currentDate {
    display: flex;

    &::before {
      background-color: $gray;
      content: '';
      display: inline-block;
      height: 20px;
      margin-#{$end}: 12px;
      mask-image: getIconUrl('calendar');
      mask-position: center;
      mask-repeat: no-repeat;
      mask-size: contain;
      width: 20px;
    }
  }

  &-date {
    font-weight: 600;
    font-size: 14px;
  }

  &-edit {
    display: flex;
  }

  &-editBtn {
    @include button-link;

    margin: auto;

    &::before {
      background-color: $primary;
      content: '';
      display: inline-block;
      height: 20px;
      margin-#{$end}: 12px;
      mask-image: getIconUrl('pencil');
      mask-position: center;
      mask-repeat: no-repeat;
      mask-size: contain;
      width: 20px;
    }
  }

  &-editHeader {
    align-items: center;
    border-bottom: 1px solid $gray-lighter;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  &-editTitle {
    @include text-l-figma;

    flex: 1;
    font-weight: 600;
  }

  &-closeEditBtn {
    @include button-link;

    display: flex;

    &::before {
      background-color: $gray;
      content: '';
      display: inline-block;
      height: 20px;
      mask-image: getIconUrl('window-close');
      mask-position: center;
      mask-size: contain;
      mask-repeat: no-repeat;
      width: 20px;
    }
  }

  .Checkbox {
    &-wrapper {
      display: flex;
      gap: 12px;
    }
  }

  .Autocomplete {
    input:hover + .Autocomplete-clearButton,
    .Autocomplete-clearButton:hover {
      display: block;
    }

    &-clearButton {
      display: none;
      top: 29px;
    }
  }

  .Datepicker {
    &-icon-clear {
      top: 37px;
    }
  }

  .Form {
    position: relative;
    z-index: 1;

    input {
      background-image: none;
    }

    .Datepicker {
      &-icon-calendar {
        display: none;
      }

      &-datepicker.disabled {
        input {
          border-color: #ccc;
          // background-color: $white;

          // &::placeholder {
          //   color: $input-color;
          //   opacity: 1;
          // }
        }
      }
    }

    form {
      background: $gray-1;
      border: 1px solid $gray-2;
      border-radius: $border-radius;
      box-shadow: $box-shadow-sm;
      margin-bottom: 0;
      padding: 24px 16px;
    }

    .Autocomplete:nth-of-type(1),
    .Datepicker:nth-of-type(1) {
      margin-#{$end}: 0;
      position: relative;

      &:after {
        background: url($icons-sprite + '#chevron-#{$end}') no-repeat center $white;
        content: '';
        filter: grayscale(100%);
        height: 20px;
        box-shadow: inset 0 0 0px 18px rgba(255, 255, 255, 0.5);
        border-radius: 10px;
        position: absolute;
        #{$end}: -10px;
        top: 20px;
        width: 20px;
        z-index: 1;

        @include mobile {
          background-image: url($icons-sprite + '#chevron-down');
          bottom: -10px;
          #{$start}: 50%;
          margin-#{$start}: -10px;
          top: unset;
        }
      }

      input {
        border-#{$end}-style: dotted;
        border-top-#{$end}-radius: 0;
        border-bottom-#{$end}-radius: 0;

        @include mobile {
          border-#{$end}-style: solid;
          border-bottom-style: dotted;
          border-top-#{$end}-radius: 3px;
          border-bottom-#{$start}-radius: 0;
        }
      }
    }

    .Autocomplete:nth-of-type(2),
    .Datepicker:nth-of-type(2) {
      @include mobile {
        margin-top: 6px;
      }

      input {
        padding-top: 20px;
        padding-#{$start}: 10px;
        padding-#{$end}: 0;
        padding-bottom: 0;
        border-bottom-#{$start}-radius: 0;
        border-#{$start}: none;
        border-top-#{$start}-radius: 0;
        @include mobile {
          border-bottom-#{$start}-radius: 3px;
          border-#{$start}: 1px solid $gray-4;
          border-top-#{$end}-radius: 0;
          border-top: none;
        }
      }
    }
  }

  // modifiers

  &--RT,
  &--RT.SearchCombo--wallet,
  &--RT.SearchCombo--availabilityCombo {
    .Checkbox {
      display: flex;
      margin-#{$start}: 20px;

      &-wrapper {
        align-self: center;
      }

      @include tablet-down {
        margin: 20px 0;
      }
    }

    @include tablet {
      .Datepicker {
        min-width: unset;
      }
    }
  }

  &--RT.SearchCombo--availabilityCombo {
    .Checkbox {
      padding: 0 20px;
      &-wrapper {
        align-self: center;
      }
    }
  }

  &--OW {
    .Checkbox {
      display: none;
    }
  }

  // availability combo
  &--availabilityCombo {
    .Autocomplete {
      min-width: 25%;
    }

    .SearchCombo {
      &-submit {
        padding: 18px 24px;
      }
    }
  }

  &--aycf {
    .SearchCombo-formWrapper:before {
      @include hero-private('aycf');
    }
  }

  &--mp {
    .SearchCombo-formWrapper:before {
      @include hero-private('mp');
    }
  }

  // wallet combo
  &--wallet {
    .SearchCombo {
      &-formWrapper {
        padding: 128px 48px 0;
        position: relative;

        @include mobile {
          padding: 100px 20px 20px;
        }
      }

      &-row:last-child {
        flex-wrap: wrap;

        .SearchCombo-fieldsContainer {
          flex-wrap: wrap;
          min-width: 100%;

          @include mobile {
            flex-direction: row;
          }
        }
      }

      &-submit {
        bottom: 0;
        padding: 18px;
        position: absolute;
        #{$end}: 0;

        @include tablet-down {
          position: static;
          width: 100%;
        }
      }
    }

    .Autocomplete {
      margin: 0 0 24px 0;
      min-width: 50%;

      @include mobile {
        min-width: 100%;

        &:first-child {
          margin-bottom: 0;
        }
      }

      input:focus {
        border-color: $gray-4;
      }
    }

    .Datepicker {
      flex: none;
      margin: 0;
      min-width: 40%;

      @include tablet-down {
        margin: 0 0 24px 0;
        min-width: none;
        width: 50%;
      }

      @include mobile {
        min-width: 100%;

        &:first-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  &--withDisplay {
    &.is-edit {
      .SearchCombo-formWrapper {
        @include mobile {
          padding: 20px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      .SearchCombo-fieldsContainer {
        @include mobile {
          border-bottom: 1px solid $gray-lighter;
          padding-bottom: 20px;
          margin-bottom: 12px;
        }
      }

      .SearchCombo-editHeader {
        display: flex;
      }
    }
  }
}

.SearchCombo--disabled {
  .SearchCombo-row {
    opacity: 0.5;
  }

  .Autocomplete,
  .Datepicker {
    input.input {
      background-color: $white;
    }
  }
}
