html,
body {
  height: 100%;
  max-width: 100%;
  width: 100%;
  background-color: $gray-1;
}

body {
  padding-top: $header-height;
}

.SiteWrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  main {
    display: flex;
    flex: 1;
    flex-direction: column;

    > div {
      display: flex;
      flex-direction: column;
      flex: 1;

      > div:not(.Header):not(.CustomizePlan-cartContainer):not(.Booking-cartContainer) {
        flex: 1;
      }
    }
  }
}

.PageContent {
  margin: 0 auto;
  max-width: 100%;
  width: $desktop;
  position: relative;
  padding-bottom: 24px;

  @include mobile {
    padding: 0 20px;
  }

  @include tablet {
    padding: 0 32px;
  }
}

.PrivatePage {
  &-leftMenu {
    flex: none;
    width: 28%;

    @include mobile {
      width: 100%;
    }
  }

  &-title {
    @include h2-figma;
    margin-bottom: 20px;

    @include mobile {
      text-align: center;
    }
  }
}

.Footer {
  margin-top: auto;
}

.is-loading.default-loader:before {
  z-index: 999;
}

[v-cloak] {
  display: none !important;
}

.hide {
  display: none;
}

.FootNotes {
  width: $desktop;
  padding-bottom: 32px;
  gap: 12px;

  @include tablet-down {
    width: 100%;
  }

  p {
    @include text-s-figma;
    color: $gray-4;
  }
}
