.W6Plans {
  &-filterSummary {
    margin-top: 32px;
    margin-bottom: 16px;
  }

  &-filterDescription {
    @include text-l-figma;

    strong {
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  .StationsFilter {
    margin-top: -50px;
    
    @include mobile {
      margin-top: 0;
    }
  }
}