article.CvoFormBuilder, article.FormBuilder {
  h1 {
    @include h7-figma;
    margin-bottom: 16px;
  }

  p {
    @include text-s-figma;

    strong {
      color: $secondary;
    }
  }

  form {
    background-color: white;
    padding: 24px 16px;
    margin-bottom: 0;
  }

  fieldset {
    border: none;

    & + fieldset {
      margin-top: 32px;
    }
  }

  label {
    @include text-s-figma;
    color: $gray-4;
    text-transform: uppercase;
    font-weight: 700;

    &:not(:last-child) {
      margin-bottom: unset
    }
  }

  .CvoFormBuilder-description, .FormBuilder-description {
    margin-bottom: 22px;
  }

  @import './form-elements';
}

#subscriptionDetails{
  margin-top: -80px;
}

#subscriptionDetails article.CvoFormBuilder form {
  background: none;
}

#subscriptionDetails article.CvoFormBuilder {
  form {
    margin-top: 35px;
    padding-#{$start}: 0;
  }

  h1 {
    font-size: 26px;
    font-weight: normal;
    letter-spacing: 0.5pt;
    text-transform: none;
  }
  .CvoRadio {
    height: 45px;
    &-controlsContainer {
      height: auto;
      .control {
        border: 0px;
        flex-grow: 0;
        margin-#{$end}: 20px;
        label {
          @include text-m-figma;
          color: $gray-5;
          display: flex;
          font-size: 17px;
          height: auto;
          justify-content: $start;
          margin-#{$start}: 35px;
        }
        input {
          appearance: none;
          background-color: $gray-1;
          border-radius: 50%;
          display: inline;
          height: 23px;
          outline: 2px solid $gray-4;
          position: absolute;
          text-align: center;
          width: 23px;
          &:checked {
            border: 5px solid $secondary;
            box-shadow: inset 1em 1em white;
            outline: 2px solid $secondary;
          }
        }
      }
    }
  }

  .acceptTerms {
    margin-top: 10px;
  }
}
