.AvailabilityPage {
  &--no-result {
    display: flex;
    flex-direction: column;
  }

  &-noResultMessage {
    margin: auto;

    h1 {
      color: $gray-4
    }
  }

  &-noResultFootnote {
    @include text-xs-figma;
    color: $gray-4;
    margin-top: 32px;
  }

  .AvailabilityConfirmation {
    display: flex;
    gap: 20px;

    @include mobile {
      flex-flow: column-reverse;
    }

    &-tip {
      height: 58px;
      background-color: $white;
      box-shadow: $box-shadow;
      @include text-m-figma;
      padding: 12px;
      display: flex;
      gap: 5px;
      align-items: center;

      .wizz-icons:before {
        width: 40px;
        height: 40px;
        margin-#{$end}: 12px;
      }
    }

    button {
      max-width: 180px;
    }
  }
}
