@mixin circle($color, $size) {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $size;
  width: $size;
  min-width: $size;
  min-height: $size;
  border-radius: calc(#{$size} / 2);
  border: 1px solid $color;
}

@mixin circle-icon-wrapper($size) {
  background: $white;
  border-radius: 50%;
  box-shadow: $box-shadow-sm;
  display: flex;
  height: $size;
  margin-bottom: 8px;
  margin-#{$end}: 0;
  width: $size;
  min-height: $size;
  min-width: $size;

  > span {
    margin: auto;
  }
}

@mixin card {
  background-color: $white;
  box-shadow: $box-shadow;
  padding: 12px 24px 24px 24px;
}

@mixin hero-private($product) {
  content: '';
  display: block;
  background-image: url($s3-base-url + '/images/hero-private/' + $product + '-' + $direction + '-lg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  height: 230px;
  #{$start}: 0;
  position: absolute;
  width: 100%;
  top: 0;
  background-position-x: #{$start};

  @include mobile {
    height: 100%;
    background-image: url($s3-base-url + '/images/hero-private/' + $product + '-' + $direction + '-sm.webp');
  }
}
