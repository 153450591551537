.CvoFlightDateSelector {
  box-shadow: $box-shadow;
  .CvoFlightDateSelector-selectedDate {
    .CvoCarouselSlider-slideInner {
      border: 2px solid $secondary;
    }
  }
  .CvoCarouselSlider {
    background: $gray-1;
    padding: 0px 200px;

    &-viewport {
      @include mobile {
        margin-#{$start}: -20px;
      }
    }

    @include tablet-down {
      padding: 0px 30px;
    }
    @include mobile {
      padding: 0px 10px;
    }
    &-carousel {
      display: flex;
      background-color: unset;
      align-items: center;
      gap: 40px;
    }
    &-control {
      background-color: $secondary;
      border: none;
      display: inline-block;
      height: 24px;
      width: 24px;
      position: unset;
      transform: unset;
      z-index: 0;
      svg {
        display: none;
      }
      &--prev {
        order: -1;
        mask-image: url($icons-sprite + '#chevron-#{$start}');
      }
      &--next {
        mask-image: url($icons-sprite + '#chevron-#{$end}');
        margin-#{$start}: -40px;
      }
    }

    &-slide, &-slideInner {
      width: 60px;
      height: 40px;
    }
    &-slideInner {
      border: 1px solid $gray-5;
      border-radius: 3px;
      padding: 6px;
      background-color: white;
      cursor: pointer;
      p {
        @include text-xs-figma;
        color: $gray-5;
        font-weight: 700;
        background: unset;
        text-align: center;
      }
    }
  }

  &-disabled {
    .CvoCarouselSlider {
      &-slideInner {
        background-color: $gray-1;
        border-color: $gray-2;
        &:before, &:after {
          position: absolute;
          content: '';
          background: $gray-2;
          display: block;
          width: 100%;
          height: 1px;
          transform: rotate(-33.69deg);
          #{$start}: 0;
          #{$end}: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }
        &:after {
          transform: rotate(33.69deg);
        }
      }
    }
  }
}
