$header-circle-size: 66px;
$selected-width: 409px;

.CvoSearchResult {
  &-selectedFlight {
    display: flex;
    flex-direction: row;
    gap: 64px;
    .CvoSearchResult-selectedFlight {
      page-break-after: always;
    }
    @include tablet-down {
      flex-flow: column nowrap;
      page-break-after: unset;
      margin: auto;
    }
  }
  .CvoSearchResultHeader {
    background-color: $gray-2;
    padding: 0 12px;
    min-height: 58px;
    display: flex;
    gap: 16px;
    box-shadow: $box-shadow;
    @include tablet-down {
      margin: auto;
    }

    .icon-wrapper {
      @include circle-icon-wrapper(calc(#{$header-circle-size}));
      margin-top: calc(#{$header-circle-size} * -0.5);
      display: inline-flex;
    }
    .wizz-icons.airplane::before {
      height: calc(#{$header-circle-size} * 2/3);
      width: calc(#{$header-circle-size} * 2/3);
    }

    .wizz-icons.flip {
      transform: scaleX(-1);
      -moz-transform: scaleX(-1);
      -webkit-transform: scaleX(-1);
      -ms-transform: scaleX(-1);
    }

    .route {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 8px;
      .stationName {
        @include h3-figma;
        text-transform: unset;
      }
      .stationCode {
        @include h6-figma;
        color: $gray-5;
      }

      .arrow {
        width: 40px;
        height: 1px;
        background-color: $gray-5;
        position: relative;
        &:after {
          content: '';
          display: inline-block;
          width: 8px;
          height: 2px;
          background-color: $gray-5;
          transform: rotate(45deg);
          position: absolute;
          #{$end}: -1px;
          bottom: 2px;
          border-radius: 1px;
        }
        &:before {
          content: '';
          display: inline-block;
          width: 8px;
          height: 2px;
          background-color: $gray-5;
          transform: rotate(-45deg);
          position: absolute;
          #{$end}: -1px;
          bottom: -3px;
          border-radius: 1px;
        }
      }
    }

    &.CvoSearchResultHeader-selectedFlight {
      max-width: $selected-width;
      padding-top: 16px;
      padding-bottom: 16px;
      min-height: 94px;
      .route .arrow {
        flex-grow: 1;
        height: 2px;
        background-color: $primary;
        &:after {
          background-color: $primary;
        }
        &:before {
          background-color: $primary;
        }
      }
      .icon-wrapper {
        margin-top: -47px;
      }
    }
  }

  .CvoSearchResult {
    &-selectedFlight {
      display: flex;
      flex-direction: column;
      gap: 0px;
      max-width: $selected-width;
    }
  }

  .CvoSearchResultFooter {
    p {
      @include text-s-figma;
      color: $gray-4;
    }
  }
}
