@mixin button-base {
  font-family: $montserrat;
  border: 2px solid;
  border-radius: $border-radius;
  box-sizing: border-box;
  color: $white;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 600; 
  height: auto;
  line-height: 19.2px;
  padding: 10px 16px 8px;
  text-decoration: none;
  text-transform: uppercase;

  &[disabled],
  &.disabled {
    background-color: $gray-3;
    border-color: $gray-3;
    color: $white;
    opacity: 1;
    pointer-events: none;
    user-select: none;
  }

  &:active,
  &:focus,
  &:focus:not(:active) {
    box-shadow: none;
    outline: none;
  }
}

@mixin button-primary {
  @include button-base;

  background-color: $primary-light;
  border-color: $primary-light;

  &:hover {
    background-color: $primary-lighter;
    border-color: $primary-lighter;
    color: $white;
  }
  
  &:active,
  &:focus {
    background-color: $primary;
    border-color: $primary;
    color: $white;
  }
}

@mixin button-secondary {
  @include button-base;
  
  background-color: $secondary;
  border-color: $secondary;

  &:hover {
    background-color: $secondary-light;
    border-color: $secondary-light;
    color: $white;
  }

  &:active,
  &:focus {
    background-color: $secondary-dark;
    border-color: $secondary-dark;
    color: $white;
  }
}

@mixin button-link {
  @include button-base;
  
  background-color: transparent;
  border-color: transparent;
  color: $secondary;
  font-size: 14px;
  text-decoration: underline;

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    border-color: transparent;
    color: $secondary;
  }
}

@mixin --button-primary-inverted {
  background-color: $white;
  border-color: $gray-2;
  color: $primary-light;

  &:hover {
    background-color: $white;
    border-color: $gray-2;
    color: $primary-lighter;
  }
  
  &:active,
  &:focus {
    background-color: $white;
    border-color: $gray-2;
    color: $primary;
  }
}

@mixin --button-secondary-inverted {
  background-color: $white;
  border-color: $gray-2;
  color: $secondary;
  
  &:hover {
    background-color: $white;
    border-color: $gray-2;
    color: $secondary-light;
  }
  
  &:active,
  &:focus {
    background-color: $white;
    border-color: $gray-2;
    color: $secondary-dark;
  }
}

@mixin --button-large {
  font-size: 20px;
  line-height: 24px;
  padding: 12px 20px;
}

@mixin --button-small  {
  padding: 6px 12px 4px;
}

@mixin --button-full  {
  display: block;
  width: 100%;
}


button,
.button {
  @include button-secondary;
}

.ActionButton {
  display: flex;
  gap: 8px;
  align-items: center;
  
  i {
    @extend .wizz-icons;
  }
}