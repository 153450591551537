.NotificationManager {
  position: fixed;
  width: 100%;
  z-index: 9;

  .Notification {
    &-inner {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row-reverse;
      padding: 5px 0 5px 4px;
    }

    &-closeButton {
      background-color: $gray-5;
      mask-image: url($icons-sprite + '#close');
      background-repeat: no-repeat;
      height: 21px;
      width: 21px;
      padding: 0;
      margin-#{$end}: 16px;
    }

    &-content {
      padding: 12px;
    }

    p {
      font-family: Arial, sans-serif;
    }

    &--danger {
      background-color: $danger-light;

      p {
        color: $danger-color;
      }
    }

    &--warning {
      background-color: $white;

      p {
        color: $warning-color;
      }
    }

    &--success {
      background-color: $success-bg;

      p {
        color: $success-color;
      }
    }
  }
}