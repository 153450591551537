.SpreedlyContainer {
  column-gap: 20px;
  row-gap: 18px;

  @include mobile {
    grid-template-areas:
    "column-1 column-1"
    "column-2 column-3"
    "column-4 column-4";
    grid-template-columns: 1fr 1fr;
  }

  @include tablet {
    grid-template-areas:
    "column-1 column-2 column-3"
    "column-4 column-4 column-4";
    grid-template-columns: 2fr 1fr 1fr;
  }

  .spreedlyDate .Spreedly-label {
    @include text-s-figma;
    font-weight: bold;
    padding: 0 8px;
    position: relative;
    text-transform: uppercase;
    display: block;
    color: $gray-4;
  }

  .Spreedly-label {
    position: absolute;
    top: 8px;
  }

}

.Spreedly {
  &-input, &-month, &-year, &-cardHolderName {
    font-size: 16px;
    font-family: Arial, sans-serif;
    color: $input-color;
  }
  &-month, &-year {
    border: none;
    width: calc(50% - 3px);
    display: inline-flex;
    height: 38px;
    outline: none;
    padding-left: 9px;

    &::placeholder {
      color: $gray-4;
      opacity: 0.5;
      font-size: 16px;
    }
  }

  &-buttonRow {
    margin-top: 20px;
    text-align: right;
  }
}

