.CvoPurchaseConfirmation {
  background-color: $white;
  padding: 12px 48px 32px 48px;
  box-shadow: $box-shadow;
  &-title, &-price, &-text {
    display: none;
  }
  &-container {
    display: flex;
    flex-wrap: wrap;
    h1.CvoPurchaseConfirmation-successMessage {
      @include h2-figma;
      break-after: always;
      color: $success;
      line-height: 35px;
      display: flex;
      align-items: center;
      margin-#{$start}: 12px;
    }

    button {
      @include button-secondary;
      height: 43px;
      align-self: center;
      @include tablet-down {
        width: 100%
      }
    }
  }
  .icon-wrapper {
    @include circle-icon-wrapper(58px);
  }
  .wizz-icons.check::before {
    filter: $green-icon;
    height: 35px;
    width: 35px;
  }
  &-description {
    @include desktop {
      flex-basis: 100%;
      margin-#{$end}: 55px;
    }
    p + * {
      margin-top: 20px;
    }
    ul {
      list-style: disc;
      padding-#{$start}: 25px;
    }
  }

  &-description, button {
    margin-top: 30px
  }
}
