.CvoNavigation {
  @include mobile {
    margin-bottom: 24px;
  }

  &-nav {
    display: flex !important; // always show the nav, override default mobile behaviour
    flex-direction: column;

    @include mobile {
      flex-direction: row;
      justify-content: unset;
    }
  }

  &-item {
    $icon-size: 36px;
    $icon-margin: 20px;

    @include text-l-figma;
    
    align-items: center;
    color: $gray-4;
    display: flex;
    font-weight: 400;
    padding: 8px 0;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;

    @include mobile {
      flex: 1;
      display: block;
      text-align: center;
      
      &:not(:last-child) {
        margin-#{$end}: 18px;
      }
    }

    &.is-active {
      color: $primary;
    }
    
    &::before {
      background-repeat: no-repeat;
      background-size: cover;
      content: '';
      display: block;
      filter: grayscale(100%);
      height: $icon-size;
      margin-#{$end}: $icon-margin;
      opacity: 0.65;
      width: $icon-size;

      @include mobile {
        content: none;
      }
    }

    &.is-active::before {
      filter: none;
      opacity: 1;
    }

    &:nth-child(1)::before {
      background-image: url($icons-sprite + '#plan');
    }

    &:nth-child(2)::before {
      background-image: url($icons-sprite + '#personal-info');
    }

    &::after {
      content: '';
      border-bottom: 2px solid $gray-4;
      bottom: 0;
      display: block;
      position: absolute;
      #{$end}: 0;
      width: calc(100% - #{$icon-size} - calc(#{$icon-margin} / 2));

      @include mobile {
        width: 100%;
      }
    }

    &.is-active::after {
      border-color: $primary;
    }
  }

  &-hamburgerWrapper {
    display: none !important; // always hide hamburger and mobile mode
  }
}