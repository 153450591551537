$benefitsCircle: 74px;

.CvoTiles {
  margin: auto;
  padding-#{$start}: 20px;
  padding-#{$end}: 20px;

  @include mobile {
    width: 100%;
  }

  h1 {
    @include h2-figma;
  }

  &-container {
    justify-content: space-evenly;
    margin-top: 24px;
    
    @include mobile {
      flex-flow: column;
    }
  }

  &-tile {
    display: unset;
  }
}

.CvoTiles--benefits {
  .CvoTiles-tile {
    display: flex;
    max-width: 345px;
    
    &:not(:last-child) {
      margin-#{$end}: 12px;

      @include mobile {
        margin-bottom: 24px;
        margin-#{$end}: auto;
      }
    }
    
    @include mobile {
      margin-#{$start}: auto;
      margin-#{$end}: auto;
      max-width: none;
      width: 100%;
    }
  }

  .CvoTiles-imageContainer {
    display: inline-block;
    background-color: white;
    border: 1px solid $gray-4;
    border-radius: calc(#{$benefitsCircle} / 2);
    height: $benefitsCircle;
    margin-bottom: calc(-#{$benefitsCircle} / 2);
    margin-#{$start}: auto;
    margin-#{$end}: auto;
    position: relative;
    width: $benefitsCircle;
    z-index: 1;
    
    img {
      padding: 12px;
    }
  }

  .CvoTiles-tile > span {
    flex: unset;
  }

  .CvoTiles-textContainer {
    background-color: white;
    display: block;
    height: 100%;
    padding: 64px 24px 32px 24px;
    min-height: 200px;
    box-shadow: $box-shadow;
    
    @include text-m-figma;
    
    @include tablet-down {
      min-height: auto;
    }

    @include mobile {
      height: auto;
    }

    .title {
      @include text-xl-figma;

      display: block;
      color: $secondary;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 16px;
    }
  }
}

.CvoTiles--steps {
  .CvoTiles-container {
    @include tablet-down {
      flex-flow: column;
    }
  }

  .CvoTiles-tile {
    width: 231px;
    max-width: 231px;
    
    @include tablet-down {
      width: 100%;
      max-width: unset;
    
      + .CvoTiles-tile {
        margin-top: 48px;
      }
    }
  }

  .CvoTiles-tileText {
    display: flex;
    flex-flow: column;
    height: 100%;
    align-items: center;
    text-align: center;
    
    @include tablet-down {
      flex-flow: row;
      width: 100%
    }

    span {
      @include text-l-figma;
    }

    .step {
      @include circle($gray-2, 70px);
      
      span {
        @include h3-figma;
        @include circle($gray-2, 56px);
      }
      
      @include tablet-down {
        flex: 0 0 70px;
      }
    }

    .text {
      @include tablet-down {
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        text-align: #{$start};
        padding-#{$start}: 32px;
        flex-grow: 1;
      }
    }

    span.title {
      text-transform: uppercase;
      color: $secondary;
      font-weight: 600;
      display: block;
      margin: 24px 0px;
      
      @include tablet-down {
        margin-bottom: 12px;
      }
    }
  }
}

#steps {
  background-color: white;
}

$el: '.CvoTiles';

.CvoTiles {
  &--redeemSuccess {
    @extend .CvoTiles--benefits;

    padding: 0;
  
    #{$el}-container {
      justify-content: space-between;
    }

    #{$el}-textContainer {
      height: auto;
    }
  }
}