@mixin heading-base {
  font-family: $montserrat;

  color: $primary;
  line-height: 125%;
  letter-spacing: -0.03em;
}

@mixin h1-figma($transform: uppercase) {
  color: $primary;
  font-family: $source-sans;
  font-size: 56px;
  font-weight: 700;
  letter-spacing: -0.03em;
  text-transform: $transform;
}

@mixin h2-figma($transform: none) {
  
  @include heading-base;
  
  font-weight: 300;
  font-size: 24px;
  text-transform: $transform;
}

@mixin h3-figma($transform: uppercase) {
  
  @include heading-base;

  font-weight: 600;
  font-size: 22px;
  text-transform: $transform;
}

@mixin h4-figma($transform: uppercase) {
  
  @include heading-base;

  font-weight: 600;
  font-size: 18px;
  text-transform: $transform;
}

@mixin h5-figma($transform: uppercase) {
  
  @include heading-base;
  
  font-family: $source-sans;
  font-weight: 700;
  font-size: 16px;
  text-transform: $transform;
}

@mixin h6-figma($transform: uppercase) {
  
  @include heading-base;
  
  font-weight: 600;
  font-size: 14px;
  text-transform: $transform;
}

@mixin h7-figma($transform: uppercase) {
  @include heading-base;
  
  font-family: $source-sans;
  font-weight: 700;
  font-size: 12px;
  text-transform: $transform;
}

h1 {
  @include h3-figma;
}

h2 {
  @include h4-figma;  
}