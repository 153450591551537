.CvoClose {
  background-color: $gray-5;
  mask-image: url($icons-sprite + '#close');
  background-repeat: no-repeat;
  height: 24px;
  padding: 0;
  width: 24px;

  &::after,
  &::before {
    content: none;
  }
}