.Footer, .footerWrapper {
  background-color: $primary-dark;
}

.CvoSimpleFooter {
  margin: auto;
  padding-block: 16px;

  &-copyright,
  &-itemsList {
    @include mobile {
      padding: 12px 0;
    }
  }

  img {
    height: 18px;
    margin-#{$start}: 12px;
    vertical-align: middle;
  }

  span, li a, li a:visited {
    @include text-s-figma;
    color: white;
    font-weight: 700;
    text-decoration: none;
  }
  
  li + li {
    border-#{$start}: 2px solid $primary-light;
    padding-#{$start}: 10px;
  }

  ul {
    @include mobile {
      flex-direction: row;
    }
  }
}