.CvoToggleButton {
  display: flex;
  &-buttonsContainer {
    justify-content: unset;
    margin: auto;
    label {
      width: 128px;
      padding: 26px 34px;
      border: 1.5px solid $gray-4;
      background: white;
      display: flex;
      flex-flow: row-reverse;
      &:first-of-type {
        border-radius: 3px 0 0 3px;
      }
      &:last-of-type {
        border-radius: 0 3px 3px 0;
      }
      p.CvoToggleButton-title {
        @include text-m-figma;
        color: $gray-4;
      }
      .CvoToggleButton-htmlContent {
        img {
          width: 20px;
          height: 20px;
          min-height: 20px;
          min-width: 20px;
          margin-#{$end}: 6px;
          &.is-selected {
            display: none;
          }
        }
      }
      &.is-selected {
        border: 1.5px solid $secondary;
        box-shadow: $box-shadow;
        p {
          @include text-m-figma;
          color: $primary;
          font-weight: 700;
        }
        .CvoToggleButton-htmlContent{
          img {
            display: none;
            &.is-selected {
              display: block;
            }
          }
        }
      }
    }
  }
}

.plans {
  p.CvoToggleButton-description {
    @include text-m-figma;
    text-align: center;
    color: $gray-5;
  }
}
