.Hero {
  &-content {
    background-size: cover;
    background-position: $end bottom;
    display: flex;
    height: 800px;
    padding: 0 calc((100vw - #{$desktop}) / 2);
    background: url($s3-base-url + '/images/hero-public/hero-desktop-' + $direction + '.webp');

    @include mobile {
      background-position: $end 100%;
      background-size: cover;
      background: linear-gradient(#3337, #3330 75%), url($s3-base-url + '/images/hero-public/hero-mobile.webp');
      height: 600px;
    }
  }

  &-contentInner {
    display: flex;
    flex-flow: column;
    height: auto;
    margin: auto;
    margin-#{$start}: 0;
    width: 600px;

    @include mobile {
      padding: 0 20px;
    }

    @include tablet {
      padding: 0 32px;
    }


    h1 {
      color: $white;
      font-size: 52px;
      font-weight: 700;
      display: flex;
      gap: 4px;
      margin-bottom: 22px;

      img {
        height: 60px;
      }

      @include mobile {
        font-size: 26px;

        img {
          height: 36px;
        }
      }
    }

    @include mobile {
      width: calc(100% - 40px);
      margin-#{$start}: auto;
      margin-top: 24px;
    }

    h2 {
      @include text-l-figma;
      color: $white;
      margin-bottom: 22px;
      text-transform: unset;
    }

    button {
      @include --button-secondary-inverted;
      width: 335px;
      justify-content: center;

      @include mobile {
        width: 100%;
      }
    }
  }
}

.UnlimitedBanner {
  background-color: $highlight-blue;
  padding-block: 16px;
  color: white;

  @include tablet-down {
    padding-block: 12px;
    text-align: center;

    &-inner {
      flex-flow: column;
      gap: 24px;
    }
  }

  &-inner {
    display: flex;
    justify-content: space-between;

    > div:nth-child(2) {
      min-width: 325px;
      margin: 0 35px;
    }
  }

  &-title {
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
  }

  &-price {
    @include h1-figma(none);
    line-height: 67px;
  }

  &-recurrency {
    @include text-m-figma;
    letter-spacing: normal;
  }

  h2, p, del, span {
    color: $white;
  }
}
