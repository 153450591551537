// Colors
$white: #FFFFFF;

$gray-1: #F2F2F2;
$gray-2: #E1E1E1;
$gray-3: #C1C1C1;
$gray-4: #999999;
$gray-5: #343434;

$primary: #06038D;
$primary-light: #3434E0;
$primary-lighter: #6060F5;
$primary-dark: #040267;
$secondary: #C6007E;
$secondary-dark: #A6006A;
$secondary-light: #D74DA5;
$secondary-lighter: #FFF2FA;
$highlight-blue: #00B1E8;
$primary-secondary-mix: #671E75;

$success: #48CB40;
$warning: #FFB800;
$danger: #C6007E;
$danger-light: #FFF2FA;

$danger-color: #cc0f35;
$warning-color: #947600;
$success-color: #257942;

$success-bg: #effaf3;

$input-color: #363636;

$gradient: linear-gradient(180deg, $secondary 40.63%, $primary-secondary-mix 65.62%, $primary 85.42%);

// Fonts
$source-sans: 'Source Sans 3';
$montserrat: 'Montserrat';

// Border
$border-radius: 3px;
$border-radius-md: 8px;
$border-radius-full: 100px;

// Shadows
$box-shadow: 0px 4px 12px rgba(51, 51, 51, 0.25);
$box-shadow-sm: 0px 1px 4px rgba(0, 0, 0, 0.15);

// Size
$tablet: 769px !default;
$desktop: 1280px !default;
$header-height: 60px;

// Icons
$icons-sprite: $s3-base-url + '/images/sprite.css.svg';
$green-icon: hue-rotate(260deg) brightness(3.75);
$secondary-icon: brightness(0) saturate(100%) invert(12%) sepia(93%) saturate(6009%) hue-rotate(314deg) brightness(80%) contrast(104%);