@mixin text-base($family, $weight: 400) {
  color: $gray-5;
  font-family: $family;
  font-style: normal;
  font-weight: $weight
}

@mixin text-xl-figma {
  @include text-base($montserrat, 300);

  font-size: 24px;
  line-height: 29px;
}

@mixin text-l-figma {
  @include text-base($montserrat, 300);

  font-size: 20px;
  line-height: 24px;
}

@mixin text-m-figma {
  @include text-base($source-sans);

  font-size: 16px;
  line-height: 20px;
}

@mixin text-s-figma {
  @include text-base($source-sans);

  font-size: 14px;
  line-height: 18px;
}

@mixin text-xs-figma {
  @include text-base($source-sans);

  font-size: 12px;
  line-height: 15px;
}

p,
li,
span,
a {
  @include text-m-figma;
}