$duration-arrow-icon-size: 38px;

.CvoCollapsibleDirectFlightRow {
  background-color: white;
  box-shadow: $box-shadow;
  @include mobile {
    flex-flow: column;
  }

  &[latedeparture] button {
    background-color: $gray-3;
    border-color: $gray-3;
    color: $white;
    opacity: 1;
    pointer-events: none;
    user-select: none;
  }

  &-content {
    flex-basis: 80%;
    display: flex;
    flex-flow: row wrap;
    border-#{$end}: 1px solid $gray-2;
    padding: 24px;

    @include mobile {
      border-bottom: 1px solid $gray-2;
      border-#{$end}: unset;
      flex-basis: 100%;
    }

    .durationArrow {
      width: 100%;
      height: 2px;
      background-color: $gray-2;
      position: relative;
      display: inline-block;
      &:after {
        content: '';
        display: inline-block;
        width: 15px;
        height: 2px;
        background-color: $gray-2;
        transform: rotate(33deg);
        position: absolute;
        #{$end}: -2px;
        bottom: 4px;
        border-radius: 1px;
      }
      &:before {
        content: '';
        display: inline-block;
        width: 15px;
        height: 2px;
        background-color: $gray-2;
        transform: rotate(-33deg);
        position: absolute;
        #{$end}: -2px;
        bottom: -4px;
        border-radius: 1px;
      }
      .wizz-icons:before {
        margin: auto;
        margin-top: calc(#{$duration-arrow-icon-size} * -1/2);
        background-color: white;
        width: $duration-arrow-icon-size;
        height: $duration-arrow-icon-size;
      }
    }
  }

  &-date {
    order: -2;
    flex: 2 0 50%;
    @include tablet {
      margin-bottom: 2px;
    }
  }

  &-carrierContainer {
    order: -1;
    flex: 2 0 50%;
    text-align: $end;
    span {
      @include text-xs-figma;
      color: $gray-4;
    }
  }

  &-departure, &-arrival {
    flex-basis: 20%;
    min-width: 20%;
    @include tablet-down {
      flex-basis: 5%;
      min-width: 5%;
    }
    > span {
      display: flex;
      flex-flow: column;
      @include tablet-down {
        align-items: flex-start;
      }
    }
  }

  &-arrival {
    .CvoCollapsibleDirectFlightRow-hour {
      justify-content: end;
      @include desktop {
        .timezone {
          order: -1;
        }
      }
      @include tablet-down {
        align-items: flex-end;
      }
    }
    .CvoCollapsibleDirectFlightRow-city {
      justify-content: end;
      text-align: $end;
    }
    > span {
      @include tablet-down {
        align-items: flex-end;
      }
    }
  }

  &-hour {
    display: flex;
    align-items: center;
    gap: 4px;
    @include tablet-down {
      flex-flow: column-reverse;
      align-items: flex-start;
    }
    span.hour {
      @include h1-figma;
      color: $gray-5;
      @include desktop {
        line-height: 67px;
      }
      @include tablet-down {
        font-size: 28px;
        z-index: 1;
        background-color: $white;
      }
    }
    span.timezone {
      @include text-xs-figma;
      color: $gray-4;
      padding-right: 8px;
    }
  }

  &-city {
    @include text-xs-figma;
    color: $gray-4;
    @include tablet {
      margin-top: 8px;
    }
  }

  &-citiesSeparator {
    flex-basis: 60%;
    min-width: 60%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 28px 8px 0;
    @include mobile {
      flex-basis: 25%;
      min-width: 25%;
    }
  }

  &-duration {
    @include text-xs-figma;
    color: $gray-4;
    margin-top: calc(#{$duration-arrow-icon-size} / 2);
  }

  &-actions {
    display: flex;
    flex-basis: 20%;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 0 8px;

    button {
      @include --button-secondary-inverted;
      padding: 16px 53px;
      @include mobile {
        width: 100%;
      }
    }
    @include mobile {
      padding: 24px 64px;
    }
  }

  &-selectionFooter {
    background-color: $gray-1;
    display: flex;
    justify-content: space-between;
    padding: 24px;
    width: 100%;
    
    > div {
      display: flex;
      gap: 16px;
    }

    ul li {
      align-items: center;
      display: flex;
      
      &:not(:last-child) {
        margin-bottom: 12px;
      }

      img {
        width: 24px;
        margin-#{$end}: 16px;
      }
    }
  }

  &-actionCol {
    @include mobile {
      flex-basis: 100%;
    }
  }
}

.CvoSearchResult-selectedFlight {
  margin-bottom: 72px;
  .CvoCollapsibleDirectFlightRow {
    flex-flow: column;
    &-content {
      flex-basis: 100%;
      border-#{$end}: unset;
      @include mobile {
        border-bottom: unset;
      }
    }
    &-date {
      margin-bottom: 2px;
    }
    &-departure, &-arrival {
      flex-basis: 5%;
      min-width: 5%;
      > span {
        align-items: flex-start;
      }
    }
    &-arrival {
      .CvoCollapsibleDirectFlightRow-hour {
        align-items: flex-end;
      }
      > span {
        align-items: flex-end;
      }
      .timezone {
        order: unset;
        padding-right: 5px;
      }
    }
    &-hour {
      flex-flow: column-reverse;
      align-items: flex-start;
      span.hour {
        line-height: unset;
        font-size: 28px;
      }
    }
    &-city {
      margin-top: 8px;
    }
    &-citiesSeparator {
      flex-basis: 40%;
      min-width: 40%;
    }
    &-actions {
      @include mobile {
        padding: 0;
      }
    }
    &-actionCol {
      width: 100%;
      button {
        border: none;
        text-decoration: underline;
        padding: 32px 0;
        width: 100%;
      }
    }
  }
}
