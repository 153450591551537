@function getIconUrl($id) {
  @return url($icons-sprite + '#' + $id);
}

@mixin icon($id) {
  &.#{$id} {
    &:before {
      background: getIconUrl($id);
    }
  }
}

@mixin icon-mask($id) {
  &.#{$id} {
    &:before {
      mask-image: getIconUrl($id);
    }
  }
}

.wizz-icons, .subs-icons {
  &:before {
    content: '';
    display: block;
    height: 25px;
    width: 25px;
  }

  &.secondary::before {
    filter: $secondary-icon;
  }

  @include icon('account-icon');
  @include icon('airplane');
  @include icon('airplane-icon');
  @include icon('alert');
  @include icon('bag-checked');
  @include icon('calendar-image');
  @include icon('cart');
  @include icon('check');
  @include icon('credit-card-icon');
  @include icon('cross');
  @include icon('customize-icon');
  @include icon('chevron-up');
  @include icon('chevron-down');
  @include icon('idea');
  @include icon('information');
  @include icon('plan');
}

.wizz-icons-mask {
  &:before {
    content: '';
    display: block;
    height: 25px;
    width: 25px;
    mask-repeat: no-repeat;
  }

  &.secondary::before {
    filter: $secondary-icon;
  }

  @include icon-mask('airplane-takeoff');
  @include icon-mask('map-marker');
  @include icon-mask('calendar');
  @include icon-mask('information');
}
