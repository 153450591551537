.CvoCard {
  &-container {
    background-color: white;
    padding: 24px 16px 45px 16px;
    border-radius: $border-radius-md;

    .card-form {
      height: calc(100% - 2rem);
    }

    h1 {
      @include h7-figma;
      margin-bottom: 16px;
    }

    p {
      padding-bottom: 20px;
    }

  }

  &-checkbox {
    margin-top: 32px;
  }

  #payment-message {
    @include text-base($source-sans);
  }
}

.CvoEditCardStripe {
  &-container {
    background-color: white;
    padding: 0;

    h1 {
      @include h7-figma;
      margin-bottom: 16px;
      padding-top: 24px;
      padding-#{$start}: 16px;
    }

    p {
      padding-bottom: 20px;

      &:nth-child(2) {
        padding-#{$start}: 16px;
      }
    }
  }

  &-editWrapper {
    padding-#{$start}: 16px;
    padding-#{$end}: 16px;
  }

  &-actionsWrapper {
    .column {
      display: flex;
      padding-#{$start}: 0;
      padding-top: 0;

      @include mobile {
        flex-direction: column;
      }

      .button {
        @include mobile {
          width: 100%;
        }
      }

      &:first-child {
        padding-#{$start}: 16px;
      }

      &:last-child {
        padding-top: 35px;
        background-color: $gray-1;
      }
    }

    .CvoCheckbox {
      &-wrapper {
        align-items: center;
        margin-top: 25px;

        input {
          width: 20px;
          height: 20px;
          min-width: 20px;
          min-height: 20px;
        }
      }

    }
  }

  &-cancelButton {
    margin-#{$end}: 10px;
    background-color: $gray-3;
    border: 1px solid $gray-3;

    &:hover {
      background-color: $gray-3;
      border: 1px solid $gray-3;
      opacity: 0.8;
    }

    @include mobile {
      margin-bottom: 10px;
    }
  }

  #payment-message {
    @include text-base($source-sans);
  }

  [type=checkbox] {
    accent-color: $secondary;
  }
}

.CvoCardRedsys {
  &-container {
    margin: 0 1rem;
  }

  &-termsConditionsInput {
    input[type="checkbox"] {
      width: 20px;
    }

    margin-bottom: 1rem;
  }

  &-termsConditionsText {
    color: $gray-4;
    font-size: 13.5px;
  }

}

.Redsys {
  &-label {
    position: absolute;
    #{$start}: 12px;
    top: 8px;
    padding: 8px;
    color: $gray-4;
    font-size: 14px;
    font-family: "Source Sans Pro";
    font-weight: bold;
    text-transform: uppercase;
  }

  &-form {
    position: relative;

    &-cardExpiration {
      @media (min-width: $tablet) {
        padding-#{$end}: 6px;
      }
    }
  }

  &-cardExpiration {
    @media (min-width: $tablet) {
      padding-#{$end}: 6px;
    }
  }



  &-input {
    border: 1px solid $gray-4;
    border-radius: 3px;
    height: 59px;
    width: 100%;
    // TODO: add on active input
    // box-shadow: 0 0 0 0.125em rgba(50, 115, 220, 0.25);
  }

  &-cardholderNameInput {
    padding: 20px 8px 0px;
    font-size: 16px;
    font-family: Arial, sans-serif;

    &:focus-visible {
      outline: 0;
    }
  }

  &-challengeModal {
    @include overlay($top: 60px, $z-index: 3);
    text-align: center;
  }

  &-challengeIframe {
    max-width: 1280px;
    width: calc(100% - 40px);
    height: calc(100% - 160px);
    margin-top: 50px;
  }

  &-button {
    position: absolute;
    #{$end}: 20px;
    width: 121px;

    @media (min-width: $desktop) {
      #{$end}: 1px;
    }

    iframe {
      max-height: 63px;
      min-height: 41px;
      position: relative;
      z-index: 2;
    }
  }

  .columns {
    margin-bottom: 3px;
  }

}

.RedsysActions {

  &-row {
    display: inline-flex;
    height: 40px;
    width: 100%;
    margin-top: 1rem;
  }

  &-cancel {
    @extend .CvoEditCardStripe-cancelButton;

    @include mobile {
      margin-bottom: 0px;
    }
  }
}

@keyframes smooth-appear {
  to {
    opacity: 1;
  }
}

.AirwallexForm, .SpreedlyContainer {
  display: grid;
  grid-template-areas: "column-1" "column-2" "column-3" "column-4";

  .CvoForm-field--error {
    font-family: $source-sans;
    font-size: 14px;

    &.blur {
      opacity: 0;
      animation: smooth-appear 1.25s ease forwards;
      height: 4px;
      margin: 0;
      padding-top: 1px;
    }
  }
}

.AirwallexForm {
  @include tablet {
    column-gap: 20px;
    grid-template-areas:
      "column-1 column-1"
      "column-2 column-3"
      "column-4 column-4";
  }
}

.AirwallexEditCard {
  &-cancel {
    @extend .CvoEditCardStripe-cancelButton;

    @include mobile {
      margin-bottom: 0px;
    }
  }

}

@for $n from 1 through 4 {
  .Airwallex-Column-#{$n}, .Spreedly-Column-#{$n} {
    grid-area: column-#{$n};
    position: relative;
  }
}

.Airwallex, .Spreedly {
  &-input {
    border: 1px solid $gray-4;
    height: 59px;
    border-radius: 3px;
  }
}

.Airwallex {
  &-input {
    padding: 20px 8px 0;
  }

  &-label {
    position: absolute;
    top: 24px;
  }
}


.Payment {
  article.CvoFormBuilder .CvoRadio-controlsContainer {
    .control.is-checked {
      box-shadow: none;
    }
  }
}