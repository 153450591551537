// For desktop view use bulma classes that are included with cvo-library styles
$sizes: 0, 0.25rem, 0.5rem, 0.75rem, 1rem, 1.5rem, 3rem;
$mobile: 'm';

@for $i from 1 through length($sizes) {
  $n: $i - 1;
  
  @include mobile {
    // Margin
    .m-#{$mobile}-#{$n} {
      margin: nth($sizes, $i) !important;
    }
  
    .mt-#{$mobile}-#{$n} {
      margin-top: nth($sizes, $i) !important;
    }
  
    .mr-#{$mobile}-#{$n} {
      margin-#{$end}: nth($sizes, $i) !important;
    }
  
    .mb-#{$mobile}-#{$n} {
      margin-bottom: nth($sizes, $i) !important;
    }
  
    .ml-#{$mobile}-#{$n} {
      margin-#{$start}: nth($sizes, $i) !important;
    }
  
    .mx-#{$mobile}-#{$n} {
      margin-#{$end}: nth($sizes, $i) !important; 
      margin-#{$start}: nth($sizes, $i) !important;
    }
  
    .my-#{$mobile}-#{$n} {
      margin-top: nth($sizes, $i) !important; 
      margin-bottom: nth($sizes, $i) !important;
    }
  
    // Padding
    .p-#{$mobile}-#{$n} {
      padding: nth($sizes, $i) !important;
    }
  
    .pt-#{$mobile}-#{$n} {
      padding-top: nth($sizes, $i) !important;
    }
  
    .pr-#{$mobile}-#{$n} {
      padding-#{$end}: nth($sizes, $i) !important;
    }
  
    .pb-#{$mobile}-#{$n} {
      padding-bottom: nth($sizes, $i) !important;
    }
  
    .pl-#{$mobile}-#{$n} {
      padding-#{$start}: nth($sizes, $i) !important;
    }
  
    .px-#{$mobile}-#{$n} {
      padding-#{$end}: nth($sizes, $i) !important; 
      padding-#{$start}: nth($sizes, $i) !important;
    }
  
    .py-#{$mobile}-#{$n} {
      padding-top: nth($sizes, $i) !important; 
      padding-bottom: nth($sizes, $i) !important;
    }
  }
}