@mixin carousel-viewport-md {
  @media screen and (max-width: 1280px) {
    @content;
  }
}

@mixin carousel-viewport-sm {
  @media screen and (max-width: 860px) {
    @content;
  }
}

@mixin carousel-viewport-xs {
  @media screen and (max-width: 400px) {
    @content;
  }
}