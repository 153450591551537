@import './mixins/carousel-viewport';

@mixin carousel-button {
  background-color: $white;
  color: $gray-4;
  border: none;
  box-shadow: $box-shadow-sm;
  border-radius: $border-radius-full;
  width: 56px;
  height: 56px;
  padding: 16px;
  cursor: pointer;
}

$mobileCardWidth: 280px;
$cardWidth: 360px;

.CarouselSlider {
  overflow: hidden;
  
  &--plans {
    .CarouselSlider {
      &-viewport {
        margin: auto;
        overflow: hidden;
        width: calc(($cardWidth + 20px) * 3);
      }

      &-carousel {
        background: none;
        padding: 0;
      }

      &-navigation {
        padding-top: 0;
        display: flex;
        align-items: center;
        gap: 24px;
        border-radius: $border-radius-full;
        background-color: $white;
        width: $cardWidth;
        margin: 0 auto;
      }

      &-container {
        margin-#{$start}: 0px;
        display: flex;
      }

      &-navigationProgressContainer {
        display: none;
      }
      &-navigationButtonPrev,
      &-navigationButtonNext {
        @include carousel-button;
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);

        > svg {
          height: 100%;
          width: 100%;
          fill: $secondary;
        }

        &.disabled {
          cursor: default;
          > svg {
            fill: $gray-4;
          }
        }
      }

      &-navigationButtonPrev {
        left: unset;
        #{$start}: 0;
      }
      
      &-navigationButtonNext {
        right: unset;
        #{$end}: 0;
      }

      @include carousel-viewport-md {
        &-viewport {
          width: calc(($cardWidth + 20px) * 2);
        }
        &-navigationButtonPrev,
        &-navigationButtonNext { 
          position: unset;
          transform: unset;
        }
        &-navigationProgressContainer {
          display: flex;
          flex: 1;
        }
        &-navigationProgress {
          height: 8px;
          flex: 1;
          background-color: $gray-1;
  
          &:first-child {
            border-top-left-radius: $border-radius-full;
            border-bottom-left-radius: $border-radius-full;
          }
          &:last-child {
            border-top-right-radius: $border-radius-full;
            border-bottom-right-radius: $border-radius-full;
          }
          &.is-selected {
            background-color: $gray-4;
            border-radius: $border-radius-full;
          }
        }
      }

      @include carousel-viewport-sm {
        &-viewport {
          overflow: visible;
          width: $cardWidth;
        }
      }

      @include carousel-viewport-xs {
        &-viewport {
          width: $mobileCardWidth;
        }

        &-navigation {
          width: $mobileCardWidth;
        }
      }
    }

    .SlideWrapper {
      position: relative;
      padding: 24px 0;
      flex: 0 0 33.3%;

      @include carousel-viewport-xs {
        &:not(.is-selected) {
          .MultiOptionPlan {
            transform: scale(0.8) translate(36px);
          }
        }
        
        &.is-selected ~ .SlideWrapper {
          .MultiOptionPlan {
            transform: scale(0.8) translate(-36px);
          }
        } 
      }

      @include carousel-viewport-sm {
        flex: 0 0 100%;

        &:not(.is-selected) {
          .MultiOptionPlan {
            transform: scale(0.8) translate(16px);
          }
        }
        
        &.is-selected ~ .SlideWrapper {
          .MultiOptionPlan {
            transform: scale(0.8) translate(-16px);
          }
        } 
      }

      @include carousel-viewport-md {
        flex: 0 0 50%;
      }

      .MultiOptionPlan {
        transition: transform 0.75s ease-out;
      }

    }

    .MultiOptionPlan {
      height: 600px;
      margin: auto;
      width: $cardWidth;
    
      @include carousel-viewport-xs {
        height: 600px;
        width: $mobileCardWidth;
      }
    }
  }
}


[dir="rtl"] {
  .CarouselSlider {
    &--navigationButtonPrev {
      left: unset;
      right: 27px;
      transform: rotate(180deg);
    }

    &--navigationButtonNext {
      left: 27px;
      right: unset;
      transform: rotate(180deg);
    }
  }
}