.RegisterFormPrivate {
  article.CvoFormBuilder {
    form {
      background-color: unset;
      padding: unset;
    }
    fieldset {
      background-color: white;
      padding: 16px;
      &+fieldset {
        padding-top: 32px;
        margin-top: 0px;
      }
    }
    fieldset:last-child {
      background-color: unset;
      padding: 32px 0 0 0;
    }
  }
}
