.StationsFilter {
  z-index: 1;

  &.is-expanded {
    .StationsFilter-toggleButton {
      border-radius: $border-radius $border-radius 0 0;
    }
  }

  &-toggleButton {
    background-color: $white;
    border-color: $white;
    color: $primary;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    transition: border-radius 0.5s ease-in-out;

    &:hover,
    &:active,
    &:focus {
      background-color: $white;
      border-color: $white;
      color: $primary;
    }
  }

  &-content {
    background-color: $white;
    padding: 0 32px;
    transition: all 0.5s ease-in-out;

    @include mobile {
      padding: 0 16px;
      opacity: 0;
    }

    &.is-expanded {
      padding: 32px 32px;

      @include mobile {
        padding: 4px 16px 16px 16px;
        opacity: 1;
      }
    }
  }

  &-description {
    margin-bottom: 32px;
  }

  &-clearButton {
    @include button-link;
  }

  .Autocomplete:nth-of-type(1) {
    margin-#{$end}: 0;
    position: relative;

    &:after {
      background: url($icons-sprite + '#chevron-#{$end}') no-repeat center $white;
      content: '';
      filter: grayscale(100%);
      height: 20px;
      box-shadow: inset 0 0 0px 18px rgba(255, 255, 255, 0.5);
      border-radius: 10px;
      position: absolute;
      #{$end}: -10px;
      top: 20px;
      width: 20px;
      z-index: 1;

      @include mobile {
        background-image: url($icons-sprite + '#chevron-down');
        bottom: -10px;
        #{$start}: 50%;
        margin-#{$start}: -10px;
        top: unset;
      }
    }

    input {
      border-#{$end}-style: dotted;
      border-top-#{$end}-radius: 0;
      border-bottom-#{$end}-radius: 0;

      @include mobile {
        border-#{$end}-style: solid;
        border-bottom-style: dotted;
        border-top-#{$end}-radius: 3px;
        border-bottom-#{$start}-radius: 0;
      }
    }
  }

  .Autocomplete:nth-of-type(2) {
    @include mobile {
      margin-top: 6px;
    }

    input {
      padding-top: 20px;
      padding-#{$start}: 10px;
      padding-#{$end}: 0;
      padding-bottom: 0;
      border-bottom-#{$start}-radius: 0;
      border-#{$start}: none;
      border-top-#{$start}-radius: 0;

      @include mobile {
        border-bottom-#{$start}-radius: 3px;
        border-#{$start}: 1px solid $gray-4;
        border-top-#{$end}-radius: 0;
        border-top: none;
      }
    }
  }
}
