.Booking {
  display: flex;
  flex-direction: column;
  gap: 36px;

  @include mobile {
    padding-bottom: 40px;
  }

  &.Payment {
    .PageContent .Payment {
      &-title {
        margin-top: 48px;
      }

      &-subtitle {
        @include h7-figma;
        margin-bottom: 24px;
      }
    }

    .CvoCard-container {
      padding: 24px 16px;
    }

    .Notification-icon {
      position: absolute;
      right: 50px;
    }
  }
  
  &-cartContainer {
    background: $primary;
    margin-top: auto;
    
    @include mobile {
      bottom: 0;
      #{$start}: 0;
      position: fixed;
      width: 100%;
      z-index: 3;

      .PageContent {
        padding-bottom: 0;
        padding-top: 0;
      }
    }

    .CvoMiniCart .CvoMiniCart {
      &-summary {
        @include mobile {
          justify-content: space-between;
        }
      }

      &-summaryTitle {
        @include mobile {
          display: none;
        }
      }

      &-summaryTotal {
        margin-right: 0;
        margin-left: 8px;
        width: 100%;
      }

      &-expandButton {
        @include mobile {
          padding: 0;
        }
      }

      &-continueButton {
        text-transform: unset;
        
        @include mobile {
          min-width: 174px;
          max-width: 215px;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        @include tablet {
          width: unset;
        }
      }
    }
    .CvoMiniCart.is-expanded .CvoMiniCart {
      &-summaryTitle {
        @include mobile {
          display: block;
        }
      }

      &-summaryTotal {
        text-align: right;
      }

      &-continueButton {
        @include mobile {
          width: 100%;
        }
      }
    }
  }
}