.CvoAddon {
  align-items: center;
  background: $white;
  border: 2px solid $gray-2;
  display: flex;
  min-height: 180px;
  padding: 12px;
  padding-#{$end}: 48px;

  @include mobile {
    margin-bottom: 3rem !important; // override bulma
    padding: 1.5rem 3rem !important; // override bulma
  }

  // Custom media query as it breaks in smaller screens
  @media screen and (max-width: 390px) {
    padding: 20px !important; // override bulma
  }

  &-wrapper {
    align-items: center;
    flex: 1;

    @include mobile {
      flex-wrap: wrap;
    }
  }

  &-title {
    font-size: 24px;
    margin-bottom: 24px;
  }

  &-imageWrapper {
    width: 150px;

    @include mobile {
      width: 100px;
    }
  }

  &-image {
    margin: auto;
  }

  &-contentWrapper {
    padding: 0;

    @include mobile {
      flex: 1;
      margin-bottom: 30px;
    }

    li {
      padding-#{$start}: 30px;
      position: relative;

      &:not(:last-child) {
        margin-bottom: 6px;
      }

      &::before {
        background-color: $gray-5;
        content:'';
        display: block;
        filter: grayscale(100%);
        height: 25px;
        #{$start}: 0;
        margin-#{$end}: 5px;
        mask-image: url($icons-sprite + '#check');
        opacity: 0.3;
        position: absolute;
        width: 25px;
      }
    }
  }

  &-actionPriceWrapper {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    padding: 0;
    min-width: 250px;

    @include mobile {
      width: 100%;
    }
  }

  &-priceWrapper {
    text-align: center;
    margin-bottom: 8px;
    margin-top: auto;

    > span {
      color: $primary;
      font-size: 56px;
      font-weight: 700;
      line-height: normal;
    }
  }

  &-action {
    @include --button-secondary-inverted;
    @include --button-large;
    @include --button-full;
  }

  &.is-selected & {
    &-action {
      align-items: center;
      border-color: $secondary;
      display: flex;
      justify-content: center;

      &:before {
        content: '';
        background-color: $success;
        display: inline-block;
        height: 24px;
        margin-#{$end}: 10px;
        mask-image: url($icons-sprite + '#check');
        width: 24px;
      }

      &:hover {
        border-color: $secondary-light;
      }
  
      &:active,
      &:focus {
        border-color: $secondary-dark;
      }
    }
  }
}