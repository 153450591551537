.Accordion {
  &List {
    display: flex;
    flex-flow: column;
    gap: 16px;
  }

  &-header {
    height: 36px;
    padding: 0 22px 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $primary;
    border-radius: 3px;
    cursor: pointer;
  }

  &-title {
    @include h6-figma;
    color: $white;
    @media only screen and (max-width: 440px) {
      text-transform: none;
    }
  }

  &-icon::before {
    filter: brightness(100);
    margin-#{$start}: 4px;

    @media only screen and (min-width: 380px) {
      margin-#{$start}: 8px;
    }
  }

  &-content {
    background-color: $white;
    padding: 16px;
  }

  @include mobile {
    &-header {
      padding: 0 8px;
    }

    &-title {
      font-size: 12px;
    }
  }
}

.Accordion.is-open .Accordion-header {
  background-color: $gray-4;
}
