@import '@/commons/scss/components/language-selector.scss';

.LanguageSelector {
  &-panelButton {
    background-color: transparent;
    border-color: transparent;
    border-radius: 0;
    padding: 16px;

    @include mobile {
      padding-inline: 0;
      font-weight: 500;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      border-color: transparent;
    }

    &.is-active {
      background-color: $white;
      border-color: transparent;

      &:after {
        background-color: $primary;
        mask-image: getIconUrl('chevron-up');
      }
      
      .LanguageSelector {
        &-currentLanguage,
        &-separator,
        &-currentCurrency {
          color: $primary;
        }      
      }
    }

    &:after {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      mask-image: getIconUrl('chevron-down');
      background-color: $white;
      vertical-align: top;

      @include mobile {
        background-color: $primary;
      }
    }
  }

  &-currentLanguage,
  &-separator,
  &-currentCurrency {
    color: $white;

    @include mobile {
      color: $primary;
      font-weight: 500;
    }
  }

  &-panel {
    background-color: $white;
    padding: 16px;
    min-width: 300px;
    box-shadow: $box-shadow;
    right: unset;
    #{$end}: 0;

    @include mobile {
      box-shadow: none;
      position: static;
      padding: 0;
      min-width: 200px;
    }
  }

  &-panelTitle {
    font-size: 14px;
    line-height: 17.6px;
    font-weight: 300;
    color: $gray-5;
    text-transform: none;
  }

  &-panelHeader {
    margin-bottom: 24px;
  }

  .SelectField {
    margin-bottom: 24px;

    .select.is-success select,
    .select.is-success select:hover {
      border-color: transparent;
      box-shadow: unset;
    }
    
    div.select {
      padding-inline: 0;
    }

    select {
      margin: 0;
    }
  }

  &-confirmButton {
    width: 100%;
  }

  &-closePanelButton {
    display: none;
  }

  .Overlay {
    @include mobile {
      display: none;
    }
  }
}
