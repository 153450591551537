$el: '.CvoDirectFlightRow';

.CvoDirectFlightRow {
  &-col {
    flex: auto;
  }

  &-hour {
    margin-#{$end}: 8px;
  }

  &-flightCode {
    font-weight: bold;
  }

  &.LegSummary {
    &::before {
      background: url($icons-sprite + '#airplane-takeoff') no-repeat;
      content: '';
      display: inline-block;
      height: 24px;
      margin-#{$end}: 12px;
      width: 24px;
    }

    span > span {
      @include h3-figma;
      display: inline-block;
      color: $gray-5;
      font-weight: 600;
      margin-#{$end}: 12px;
      text-transform: none;

      @include mobile {
        font-size: 16px;
      }
    }

    #{$el}-city {
      margin-#{$end}: 0;
    }

    #{$el}-hour {
      @include mobile {
        display: block;
      }
    }

    #{$el}-col:not(:first-child),
    #{$el}-citiesContainer span:nth-child(3) #{$el}-hour{
      display: none
    }

    #{$el}-arrow {
      background: url($s3-base-url + '/images/arrow-right.svg') no-repeat center;
      background-size: contain;
      display: inline-block;
      height: 16px;
      width: 49px;

      @include mobile {
        height: calc(16px * 0.5);
        width: calc(49px * 0.5);
      }

      &::before {
        content: none;
      }
    }
  }

  &.LegDetails {
    max-width: 60%;
    
    @include mobile {
      max-width: 100%;
    }

    // Custom media query as it breaks in smaller screens
    @media screen and (max-width: 450px) {
      flex-direction: column;
    }
  }
}