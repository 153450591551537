.CvoMiniCart {
  color: $white;
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  width: 100%;

  span {
    color: $white;
  }
  & &-summary {
    align-items: center;
    order: 2;
    width: 100%;
    transition: padding-bottom 0.3s ease-in-out;
  }

  & &-summaryTitle {
    flex: 1;
    order: 2;
    
    h1 {
      color: $white;
    }
  }

  & &-summaryTotal {
    margin-#{$end}: 24px;
    order: 3;
    
    > span {
      font-size: 24px;
      font-weight: bold;
    }

    .CvoMiniCart-totalPrice span{
      font-size: 16px;
    }
  }

  & &-continueButton {
    order: 4;
    width: 120px;
    height: 41px;
  }

  & &-expandButton {
    order: 1;
    padding: 10px;

    &:hover,
    &:active,
    &:focus {
      background: none;
      border: none,
    }
  }

  & &-summaryArrow {
    background-color: $white;
    display: block;
    height: 25px;
    margin: 0;
    mask-image: url($icons-sprite + '#chevron-up');
    position: static;
    transform: none;
    width: 25px;
    
    &:before,
    &:after {
      content: none;
    }
  }

  & &-panel {
    background: none;
    border-bottom: 1px solid $primary-light;
    box-shadow: none;
    display: block;
    max-height: 0;
    padding: 0;
    margin: 0;
    opacity: 0;
    order: 1;
    overflow: hidden;
    padding-#{$start}: 45px;
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }

  & &-section:first-child,
  & &-section:last-child {
    display: none;
  }

  & &-section {
    border: none;
  }

  & &-itemWrapper {
    margin-bottom: 12px;
  }

  & &-itemName {
    @include text-l-figma;
    color: $white;
  }

  & &-itemDescription {
    font-size: 12px;
  }

  & &-itemValue {
    @include mobile {
      min-width: 130px;
      text-align: $end;
    }
  }

  & &-itemCurrency {
    font-weight: bold;
  }

  & &-itemPrice {
    font-weight: bold;

    span {
      font-size: 14px;
    }
  }

  &.is-expanded {
    $el: '.CvoMiniCart';

    #{$el}-summary {
      padding-bottom: 60px;
      position: relative;
    }

    #{$el}-summaryArrow {
      mask-image: url($icons-sprite + '#chevron-down');
      transform: none;
    }

    #{$el}-summaryTotal {
      margin-#{$end}: auto;
    }

    #{$el}-continueButton {
      bottom: 4px;
      position: absolute;
      #{$end}: 0;
    }

    #{$el}-panel {
      margin-bottom: 10px;
      max-height: 1000px;
      opacity: 1;
      padding-top: 20px;
    }
  }

  &.Redsys {
    .CvoMiniCart-summaryTotal {
      margin-#{$end}: 144px;
    }
    .CvoMiniCart-continueButton {
      display: none;
    }
  }
}
