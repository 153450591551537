.CvoStepsBar {
  $el: '.CvoStepsBar';

  margin: 0 auto;
  max-width: 600px;

  @include mobile{
    max-width: 400px;
  }

  & &-simple {
    justify-content: space-between;
  }

  & &-simple-item {
    display: flex;
    flex-direction: column;

    &.is-active ~ li {
      #{$el}-iconWrapper {
        border: 2px solid $gray-3;
        box-shadow: none;

        span:before {
          filter: grayscale(100%);
          opacity: 0.3;
        }
      }

      #{$el}-simple-itemText {
        color: $gray-3;
      }
    }

    &.is-active {
      #{$el}-iconWrapper {
        border: 2px solid $primary;
        box-shadow: none;
      }
    }
  }

  & &-simple &-simple-item {
    position: relative;

    &:not(:last-child):after {
      content: '- - - - -';
      font-family: $montserrat;
      color: $gray-3;
      font-size: 12px;
      font-weight: 700;
      #{$start}: 80px;
      letter-spacing: -0.08em;
      width: 30px;
      position: absolute;
      top: 20px;
      white-space: nowrap;

      @include mobile {
        content: '. . . . .';
        #{$start}: 120%;
        top: 15px;
      }

      // Custom media query as it breaks in smaller screens
      @media screen and (max-width: 440px) {
        content: '. . . . . . . . .';
        word-wrap: normal;
        #{$start}: 50px;
      }
    }

    #{$el}-iconWrapper {
      @include circle-icon-wrapper(48px);
    }
  }

  &-simple-itemText {
    @include h7-figma;

    @include mobile {
      display: none;
    }
  }

  .wizz-icons.check:before {
    filter: $green-icon;
  }

  @include mobile {
    & &-simple {
      flex-direction: row;
    }
  }
}
