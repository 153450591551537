.CvoDropdownMenu {
  position: relative;

  &-listWrapper {
    position: absolute;
    #{$end}: 0;
    z-index: 1;
    width: max-content;
    background-color: $white;
    border-radius: $border-radius;
    box-shadow: $box-shadow-sm;
  }

  &-list {
    list-style: none !important;
    padding: .25rem;
  }
}