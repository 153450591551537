.FaqsWrapper {
  h2 {
    @include h2-figma;
  }
}

.Faqs {
  &-heading {
    display: none;
  }
}
