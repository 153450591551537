.Tabs {
  &-nav ul {
    margin-top: 10px;
    display: flex;
    gap: 24px;
    border-bottom: 1px solid $gray-2;

    li {
      @include h5-figma;
      color: $gray-4;
      padding-bottom: 12px;
      cursor: pointer;

      &.is-active {
        color: $primary;
        border-bottom: 1px solid $primary;
      }
    }

    @include mobile {
      flex-flow: column;
      gap: 12px;

      li {
        padding-bottom: 8px;
      }
    }
  }

  &-wrapper {
    margin-top: 20px;
  }
}
