.CvoVerticalPlan {
  width: 359px;
  min-width: 359px;
  box-shadow: $box-shadow;
  border-radius: 3px;
  &-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-top: -130px;
  }
  background-image: linear-gradient(168deg, transparent 178px, $primary 180px);

  @include mobile {
    width: 325px;
    min-width: 325px;
    background-image: linear-gradient(168deg, transparent 158px, $primary 160px);
  }

  img {
    z-index: -1;
    position: relative;
  }

  li, span {
    color: white;
  }

  h1.CvoVerticalPlan-title {
    @include text-xl-figma;
    font-weight: 600;
    background-color: white;
    width: 178px;
    padding: 8px;
    color: $primary;
    border-radius: 4px;
    text-transform: uppercase;
    min-height: 94px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    span {
      display: block;
      @include text-m-figma;
      font-weight: 400;
      color: $primary;
    }
  }

  p.CvoVerticalPlan-description {
    @include text-m-figma;
    color: white;
    width: 100%;
    h2 {
      @include text-s-figma;
      color: white;
      font-weight: 700;
      margin: 12px;
      text-align: center;
    }
    ul {
      padding: 12px;
      text-align: #{$start};
      width: 90%;
      margin: auto;
      li:before {
        filter: brightness(100);
        display: inline-block;
        width: 16px;
        margin-#{$end}: 14px;
      }
      li.allowance::before {
        content: url($icons-sprite + '#airplane-takeoff');
      }
      li.network::before {
        content: url($icons-sprite + '#map-marker');
      }
      li.booking::before {
        content: url($icons-sprite + '#calendar');
      }
      li.commitment::before {
        content: url($icons-sprite + '#information');
      }
    }
    hr {
      border: 1px solid rgba(255, 255, 255, 0.25)
    }
  }

  + .CvoVerticalPlan {
    margin-#{$start}: 64px;

    @include tablet-down {
      margin-#{$start}: 12px;
    }

    @include mobile {
      margin-#{$start}: 0;
      margin-top: 56px;
    }
  }

  .price-wrapper {
    display: flex;
    .price {
      margin: auto;
      @include text-s-figma;
      color: white;
      .from {
        display: block;
        text-align: #{$start};
      }
      .amount {
        @include h1-figma;
        color: white;
        line-height: 67px;
      }
    }
  }

  &-fixedContent {
    width: 100%;
    button {
      background-color: white;
      color: $primary;
      border: none;
      margin-top: 8px;
    }
  }

  &.secondary {
    background-image: linear-gradient(168deg, transparent 178px, $secondary 180px);
    @include mobile {
      background-image: linear-gradient(168deg, transparent 158px, $secondary 160px);
    }
    h1, h1 span {
      color: $secondary;
    }
    .CvoVerticalPlan-fixedContent button {
      color: $secondary;
    }
  }
}
