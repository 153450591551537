.WalletPage {
  .Availability {
    &-accordion {
      .Accordion {
        &-title {
          text-transform: unset;
        }
      }
    }

    &-intro {
      @include text-s-figma;
      color: $primary;
    }

    &-footnote, &-footnote a {
      @include text-xs-figma;
      margin-top: 16px;
      color: $gray-4;
    }

    &-notification {
      padding: 16px 12px 18px 16px;
      background-color: $gray-1;
      margin: 16px 0;

      .Notification {
        &-inner {
          display: flex;
          gap: 8px;
        }

        &-content {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

.WalletModal {
  button.CvoClose {
    background-color: unset;
    order: 1;
    padding: 0px;
    min-height: 25px;
    min-width: 25px;
    &::before {
      content: '';
      display: block;
      height: 25px;
      width: 25px;
      position: static;
      transform: unset;
      background-image: none;
      mask-image: getIconUrl('cross');
      background-color: $secondary;
    }
    &::after {
      display: none;
    }
    &:hover {
      outline: none;
    }
  }
  i.unsuccessful {
      background-color: unset;
      position: static;
      padding: 0px;
      min-height: 25px;
      min-width: 25px;
      &::before {
        content: '';
        display: inline-block;
        height: 25px;
        width: 25px;
        transform: unset;
        background-image: none;
        mask-image: getIconUrl('cross');
        background-color: $secondary;
        vertical-align: top;
      }
      &::after {
        display: none;
      }
      &:hover {
        outline: none;
      }
  }
  &--success {
    h1 i {
    color: $success;
    }
  }

  &--danger {
    h1 i {
    color: $danger;
    }
  }

  h1,
  h2 {
    color: $primary;
    text-transform: initial;
    font-weight: 400;
    text-align: center;

    @include mobile {
    font-size: 24px;
    }
  }

  &-content {
    margin-bottom: 20px * 1.5;
    text-align: center;
  }

  &-actionWrapper {
    display: flex;
    justify-content: space-around;

    @include mobile {
    flex-direction: column;
    }

    p {
    font-size: 24px;
    font-weight: bold;
    line-height: 0.5;

      @include mobile {
        margin-bottom: 20px;

        br {
        display: none;
        }
      }
    }

    span {
    font-size: 24px;
    }
  }
  .vm--modal {
    height: auto !important; //overide inline styles
    @include mobile {
      #{$start}: 0 !important;
      width: auto !important;
    }
  }

}

.CvoModal {
  &-title {
    padding: 20px 60px 20px 20px;
    background-color: $gray-1;
  }
}
