.RegisterForm {
  display: flex;
  flex-direction: column;
  
  .CvoForm--disabled {
    button {
      cursor: default;
      border-color: $gray-3;
      background-color: $gray-3;
      color: $gray-1;
    }
  }

  .CvoFormBuilder {
    form {
      border-radius: $border-radius;
      padding-bottom: 16px;
    }
  }

  .CvoFormBuilder--emailValidation {
    .CvoFormBuilder {
      &-group {
        float: $start;
        width: 50%;
        margin-#{$end}: 24px;

        @include mobile {
          float: none;
          margin-#{$end}: -10px;
          width: auto;
        }
      }

      &-actions {
        float: $start;
        margin-top: 22px;

        @include mobile {
          float: none;
          margin-top: 0;
        }
      }

      &-fieldsetInner::after {
        content: "";
        display: table;
        clear: both;
      }
    }
  }

  +.CustomizePlan-cartContainer {
    flex: none !important; // TODO: hotfix, remove !important
    
    .CvoMiniCart {
      &-continueButton {
        cursor: default;
        border-color: $gray-3;
        background-color: $gray-3;
        color: $gray-1;
      }
    }
  }

}

.VerificationTip {
  background-color: white;
  padding: 16px;
  padding-top: 0px;
  p {
    @include text-s-figma;
    &.secondaryText {
      color: $gray-4;
    }
    button {
      @include --button-secondary-inverted;
      @include text-s-figma;
      color: $secondary;
      border: none;
      padding: 0;
      text-decoration: underline;
      font-weight: 700;
    }
  }
}